import { makeAutoObservable } from 'mobx';
import { message } from 'antd';
import { defaultAxios } from '@/utils';
import { createDefaultInstanceGetter } from '@/stores/utils';
import { IMemberTuoKeTongSearchData } from '.';

export interface IMemberTuoKeTongData {
    company_id: number; // cid
    license_id: number; // 团队ID
    addfr_cnt: number; // 加好友余额
    uh_cnt: number; // 极速联系余额
    expire_time: string; // 过期日期
    sub_license: number; // 席位数量
    is_sale: number; // 是否基础版
    is_batch: number; // 批量功能子账号
    is_market: number; // 是否营销
    telephone_cnt: number; // 电话数量
    promote_cnt: number; // 脉客券数量
    direct_mobile_cnt: number; // 电话直连数量
    company: string; // 公司名称
}

export interface ITuoKeTongChildData {
    friend: number | undefined;
    contact: number | undefined;
    telephone: number | undefined;
    direct_mobile: number | undefined;
    id: number;
    name: string;
    phone: string;
    sub_license_st: number;
    is_admin: number;
    addfr_left: number;
    uh_left: number;
    telephone_left: number;
    direct_mobile_left: number;
    is_sale: number;
    is_batch: number;
    is_market: number;
    expire_time: string;
}

export interface ITuoKeTongModifyPayload {
    addfrCnt: number;
    uhCnt: number;
    promoteCnt: number;
    telephoneCnt: number;
    directMobileCnt: number;
    expireTime: string;
    isSale: number;
    isMarket: number;
    isBatch: number;
}

export interface ITuoKeTongModifyChildPayload {
    subLicenseId: number;
    isAdmin: number;
    isSale: number;
    isMarket: number;
    isBatch: number;
    friend?: number;
    contact?: number;
    telephone?: number;
    direct_mobile?: number;
}

export class MemberTuoKeTongStore {
    constructor() {
        makeAutoObservable(this);
    }
    public memberTuoKeTongData: IMemberTuoKeTongData[] = [];
    public memberTuoKeTongLoading: boolean = false;
    public clearStore = () => {
        this.memberTuoKeTongData = [];
        this.memberTuoKeTongLoading = false;
    };
    public getMemberTuoKeTongData = async (
        searchData: IMemberTuoKeTongSearchData,
    ) => {
        this.memberTuoKeTongLoading = true;
        const url = '/crm/member/tuoketong/list';
        const [d, e] = await defaultAxios.get(url, {
            ...searchData,
        });
        this.memberTuoKeTongLoading = false;
        if (d === null || d.data === null) {
            message.error('查询失败~');
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            this.memberTuoKeTongData = data;
        } else {
            this.memberTuoKeTongData = [];
            message.error(data.msg || '查询失败~');
        }
        const error = e as Error | null;
        return [data, error];
    };
    public modifyTuoKeTongEquality = async (
        license_id: number,
        modifyPayload: ITuoKeTongModifyPayload,
    ) => {
        const url = '/crm/member/tuoketong/modifyLicense';
        const payload = {
            license_id: license_id,
            addfr_cnt: modifyPayload.addfrCnt,
            uh_cnt: modifyPayload.uhCnt,
            promote_cnt: modifyPayload.promoteCnt,
            telephone_cnt: modifyPayload.telephoneCnt,
            direct_mobile_cnt: modifyPayload.directMobileCnt,
            expire_time: modifyPayload.expireTime,
            is_sale: modifyPayload.isSale,
            is_market: modifyPayload.isMarket,
            is_batch: modifyPayload.isBatch,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        return data ? true : data.msg || false;
    };
    public modifyTuoKeTongChild = async (
        childPayload: ITuoKeTongModifyChildPayload,
    ) => {
        const url = '/crm/member/tuoketong/modifySubLicense';
        const payload = {
            license_id: childPayload.subLicenseId,
            is_admin: childPayload.isAdmin,
            is_sale: childPayload.isSale,
            is_batch: childPayload.isBatch,
            is_market: childPayload.isMarket,
            friend: childPayload.friend || 0,
            contact: childPayload.contact || 0,
            telephone: childPayload.telephone || 0,
            direct_mobile: childPayload.direct_mobile || 0,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        return data ? true : data.msg || false;
    };
    public deleteTuoKeTongChild = async (subLicenseId: number) => {
        const url = '/crm/member/tuoketong/delSubLicense';
        const [d, e] = await defaultAxios.get(url, {
            sub_license_id: subLicenseId,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        return data ? true : data.msg || false;
    };
    public bindTuoKeTongChild = async (
        subLicenseId: number,
        mobile: string,
    ) => {
        const url = '/crm/member/tuoketong/bindSubLicense';
        const [d, e] = await defaultAxios.get(url, {
            sub_license_id: subLicenseId,
            mobile,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        return data ? true : data.msg || false;
    };
    public unBindTuoKeTongChild = async (subLicenseId: number) => {
        const url = '/crm/member/tuoketong/unbindSubLicense';
        const [d, e] = await defaultAxios.get(url, {
            sub_license_id: subLicenseId,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        return data ? true : data.msg || false;
    };
}

export const getDefaultMemberTuoKeTongStore = createDefaultInstanceGetter(
    MemberTuoKeTongStore,
);
