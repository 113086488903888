import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Input,
    Button,
    message,
    Modal,
    InputNumber,
    Select,
    DatePicker,
} from 'antd';
import moment from 'moment';
import { LayoutStandardCrud, TableLv2 } from '@/components';
import { StoreContext } from '@/stores';
import ExpandedMemberRow from './component/ExpandedTuoKeTongRow';
import { IMemberTuoKeTongData, ITuoKeTongModifyPayload } from './store';
import './index.scss';

export interface IMemberTuoKeTongSearchData {
    cid?: string;
    phone?: number | string;
}

const MemberTuoKeTong: React.FC = () => {
    const [searchData, setSearchData] = useState<IMemberTuoKeTongSearchData>({
        cid: '',
        phone: '',
    });
    const store = useContext(StoreContext);
    const memberTuoKeTongStore = store.getDefaultMemberTuoKeTongStore();
    const {
        getMemberTuoKeTongData,
        modifyTuoKeTongEquality,
        memberTuoKeTongData,
        memberTuoKeTongLoading,
        clearStore,
    } = memberTuoKeTongStore;
    const [selectedRow, setSelectedRow] = useState<IMemberTuoKeTongData>();
    const [modifyEqualityModalVis, setModifyEqualityModalVis] = useState(false);
    const [modifyEqualityValue, setModifyEqualityValue] = useState<
        ITuoKeTongModifyPayload
    >({
        addfrCnt: 0,
        uhCnt: 0,
        promoteCnt: 0,
        telephoneCnt: 0,
        directMobileCnt: 0,
        expireTime: '',
        isSale: 0,
        isMarket: 0,
        isBatch: 0,
    });
    useEffect(() => {
        return () => {
            clearStore();
        };
    }, []);
    const handleSearch = async () => {
        if (searchData.cid || searchData.phone) {
            getMemberTuoKeTongData(searchData);
        } else {
            message.warning('请输入手机号或cid查询');
        }
    };
    const renderMemberTuoKeTongPageFilter = () => {
        return (
            <div className="tuoketong-filter-content">
                <div className="tuoketong-filter-row">
                    <div className="tuoketong-filter-item">
                        <label htmlFor="cid">cid：</label>
                        <Input
                            name="cid"
                            onChange={e => {
                                setSearchData({
                                    ...searchData,
                                    cid: e.target.value,
                                    phone: '',
                                });
                            }}
                            value={searchData.cid}
                            onPressEnter={handleSearch}
                        />
                    </div>
                    <div className="tuoketong-filter-item">
                        <label htmlFor="phone">管理员或子账号手机：</label>
                        <Input
                            name="phone"
                            onChange={e => {
                                setSearchData({
                                    ...searchData,
                                    phone: e.target.value,
                                    cid: '',
                                });
                            }}
                            value={searchData.phone}
                            onPressEnter={handleSearch}
                        />
                    </div>
                    <div className="tuoketong-button-row">
                        <Button
                            type="primary"
                            loading={memberTuoKeTongLoading}
                            onClick={() => {
                                handleSearch();
                            }}
                        >
                            查询
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const columns = [
        {
            title: '公司名称',
            key: 'company',
            dataIndex: 'company',
            width: 120,
        },
        {
            title: '团队ID',
            key: 'license_id',
            dataIndex: 'license_id',
            width: 100,
        },
        {
            title: '加好友余额',
            key: 'addfr_cnt',
            dataIndex: 'addfr_cnt',
            width: 100,
        },
        {
            title: '极速联系余额',
            key: 'uh_cnt',
            dataIndex: 'uh_cnt',
            width: 100,
        },
        {
            title: '席位数量',
            key: 'sub_license',
            dataIndex: 'sub_license',
            width: 100,
        },
        {
            title: '电话数量',
            key: 'telephone_cnt',
            dataIndex: 'telephone_cnt',
            width: 100,
        },
        {
            title: '脉客券数量',
            key: 'promote_cnt',
            dataIndex: 'promote_cnt',
            width: 100,
        },
        {
            title: '电话直连数量',
            key: 'direct_mobile_cnt',
            dataIndex: 'direct_mobile_cnt',
            width: 100,
        },
        {
            title: '是否基础版',
            key: 'is_sale',
            dataIndex: 'is_sale',
            width: 100,
            render: (v: number) => {
                return v ? '是' : '否';
            },
        },
        {
            title: '批量功能子账号',
            key: 'is_batch',
            dataIndex: 'is_batch',
            width: 100,
        },
        {
            title: '是否营销',
            key: 'is_market',
            dataIndex: 'is_market',
            width: 100,
            render: (v: number) => {
                return v ? '是' : '否';
            },
        },
        {
            title: 'cid',
            key: 'company_id',
            dataIndex: 'company_id',
            width: 100,
        },
        {
            title: '过期日期',
            key: 'expire_time',
            dataIndex: 'expire_time',
            width: 120,
            render: (v: any) => {
                return v ? moment(v).format('YYYY-MM-DD') : null;
            },
        },
    ];

    const renderModifyTuoKeTongEquality = () => {
        return (
            <Modal
                title={`修改「${selectedRow?.company}」权益`}
                visible={modifyEqualityModalVis}
                onCancel={() => {
                    setModifyEqualityModalVis(false);
                }}
                onOk={async () => {
                    if (selectedRow) {
                        const res = await modifyTuoKeTongEquality(
                            selectedRow.license_id,
                            modifyEqualityValue,
                        );
                        if (res) {
                            message.success(
                                `已为「${selectedRow.company}」修改权益`,
                            );
                            setModifyEqualityModalVis(false);
                            getMemberTuoKeTongData(searchData);
                        } else {
                            message.error('修改失败');
                        }
                    }
                }}
            >
                <div className="tuoketong-modify-equality-content">
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">加好友数量：</label>
                        <InputNumber
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    addfrCnt: value,
                                });
                            }}
                            value={modifyEqualityValue.addfrCnt}
                            defaultValue={selectedRow?.addfr_cnt}
                            min={0}
                        />
                    </div>
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">极速联系数量：</label>
                        <InputNumber
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    uhCnt: value,
                                });
                            }}
                            value={modifyEqualityValue.uhCnt}
                            defaultValue={selectedRow?.uh_cnt}
                            min={0}
                        />
                    </div>
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">脉客券数量：</label>
                        <InputNumber
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    promoteCnt: value,
                                });
                            }}
                            value={modifyEqualityValue.promoteCnt}
                            defaultValue={selectedRow?.promote_cnt}
                            min={0}
                        />
                    </div>
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">电话数量：</label>
                        <InputNumber
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    telephoneCnt: value,
                                });
                            }}
                            value={modifyEqualityValue.telephoneCnt}
                            defaultValue={selectedRow?.telephone_cnt}
                            min={0}
                        />
                    </div>
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">电话直连数量：</label>
                        <InputNumber
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    directMobileCnt: value,
                                });
                            }}
                            value={modifyEqualityValue.directMobileCnt}
                            defaultValue={selectedRow?.direct_mobile_cnt}
                            min={0}
                        />
                    </div>
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">批量功能子账号：</label>
                        <InputNumber
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    isBatch: value,
                                });
                            }}
                            value={modifyEqualityValue.isBatch}
                            defaultValue={selectedRow?.is_batch}
                            min={0}
                        />
                    </div>
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">是否基础款：</label>
                        <Select
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    isSale: value,
                                });
                            }}
                            value={modifyEqualityValue.isSale}
                        >
                            <Select.Option key={1} value={1}>
                                是
                            </Select.Option>
                            <Select.Option key={0} value={0}>
                                否
                            </Select.Option>
                        </Select>
                    </div>
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">是否营销：</label>
                        <Select
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    isMarket: value,
                                });
                            }}
                            value={modifyEqualityValue.isMarket}
                        >
                            <Select.Option key={1} value={1}>
                                是
                            </Select.Option>
                            <Select.Option key={0} value={0}>
                                否
                            </Select.Option>
                        </Select>
                    </div>
                    <div className="tuoketong-modify-flex">
                        <label htmlFor="">过期时间：</label>
                        <DatePicker
                            onChange={(date, dateString: string) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    expireTime: dateString,
                                });
                            }}
                            defaultValue={moment(selectedRow?.expire_time)}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    const renderMemberTuoKeTongTable = () => {
        return (
            <TableLv2
                style={{ height: 500 }}
                closeRowSelection={true}
                columns={columns}
                loading={memberTuoKeTongLoading}
                dataSource={memberTuoKeTongData}
                expandedRowRender={(record, index, indent, expanded) => {
                    return (
                        expanded && (
                            <ExpandedMemberRow license_id={record.license_id} />
                        )
                    );
                }}
                scroll={{ x: '2200' }}
                actions={[
                    {
                        actionKey: 'update',
                        actionCn: '修改',
                    },
                ]}
                onAction={(actionKey: string, record) => {
                    setSelectedRow(record);
                    if (actionKey === 'update') {
                        setModifyEqualityValue({
                            addfrCnt: record.addfr_cnt,
                            uhCnt: record.uh_cnt,
                            promoteCnt: record.promote_cnt,
                            telephoneCnt: record.telephone_cnt,
                            directMobileCnt: record.direct_mobile_cnt,
                            expireTime: record.expire_time,
                            isSale: record.is_sale,
                            isMarket: record.is_market,
                            isBatch: record.is_batch,
                        });
                        setModifyEqualityModalVis(true);
                    }
                }}
                actionsWidth={360}
                isFixedActions={false}
            ></TableLv2>
        );
    };
    return (
        <>
            {renderModifyTuoKeTongEquality()}
            <div className="tuoketong-content om-tuoketong-list">
                <LayoutStandardCrud
                    title={'拓客通'}
                    message={null}
                    popups={null}
                    multipleOps={null}
                    globalOps={null}
                    filters={renderMemberTuoKeTongPageFilter()}
                    dataTable={renderMemberTuoKeTongTable()}
                />
            </div>
        </>
    );
};

export default observer(MemberTuoKeTong);
