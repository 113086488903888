import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _, { filter, isArray } from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonQuery,
    TGetCommonReturnType,
    commonQueryProcess,
    commonQueryProcess2,
    getCommonQueryBody,
    getCommonProcessQueryBody,
} from '@/api/rest';
import {
    defaultWritingDataParser,
    defaultAxios,
    delay,
    arrToJson,
    isNetSuccess,
} from '@/utils';
import EE from 'eventemitter3';
import $ from 'jquery';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import { IProcessCtx } from '@/components';
import { ProductListStore } from '@/pages/om/product/list/store';
import { ContractListStore } from '@/pages/om/contract/list/store';
import { parseFeProductToBackEndContractProduct } from '@/pages/om/contract/list/utils';
import { createDefaultInstanceGetterA } from '@/stores/utilsA';

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class ProcessCatStore {
    // 数据
    public data: any[] = [];
    public setData(nextData: any[]) {
        this.data = nextData;
    }

    public sysUser: any = null;
    public setSysUser(nextSysUser: any) {
        this.sysUser = nextSysUser;
    }

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public isCate2: boolean = false;
    public setIsCate2True() {
        this.isCate2 = true;
    }

    public get pageTitle() {
        return '';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const { tableCode } = this; // just for dep
        if (!this.defaultMeta) {
            return {
                filters: [],
            };
        }
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create'].indexOf(this.actionType) > -1;
    }

    public seeDetailItem: any = null;
    public setSeeDetailItem(next: any) {
        this.seeDetailItem = next;
    }

    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }
        if (nextAction === 'see-detail') {
            this.seeDetailItem = extra;
            this.preparedProcessDetail(extra);
        }
        this.actionType = nextAction;
    }

    public submitActionType: string = '';
    public setSubmitActionType(next: string) {
        this.submitActionType = next;
    }
    public submitActionData: any = null;
    public setSubmitActionData(next: any) {
        this.submitActionData = next;
    }
    public resetSubmitActionItems() {
        this.submitActionData = null;
        this.submitActionType = '';
    }

    public processDetailCtx: any = null;
    public preparedProcessDetailLoading = false;

    public detailTableCode: string | null = null;
    public setDetailTableCode(tableCode: string | null) {
        this.detailTableCode = tableCode;
    }
    public async preparedProcessDetail(data: any) {
        const { defaultFastFilters } = this;
        const { selectedLv4MenuFilters } = defaultFastFilters;

        let detailTableCode = '';
        // 拿code的方式不一样
        if (this.listType === 'category') {
            detailTableCode = selectedLv4MenuFilters[0]?.originalItem?.formKey;
        } else {
            detailTableCode = data.formKey;
        }
        if (!detailTableCode) {
            return message.error('没有formkey');
        }

        let instanceId = '';
        if (this.listType === 'category') {
            instanceId = data.process_instance_id || data.id;
        } else {
            instanceId = data.id;
        }
        if (!instanceId) {
            return message.error('没有instanceId');
        }
        if (!data.id) {
            return message.error('没有id');
        }

        this.processDetailCtx = {
            instanceId,
            currentUser: this.sysUser?.userId,
        };
        this.detailTableCode = detailTableCode;

        // this.preparedProcessDetailLoading = true;
        // await this.fetchInstantlyMutatingDataById(data.id)
        // this.preparedProcessDetailLoading = false;
    }

    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        if (!this.defaultMeta) {
            return;
        }
        this.newDataHash = '' + Date.now();
        const initialFormData = _.cloneDeep(
            this.defaultMeta.defaultWidgetValues,
        );
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return undefined;
        // return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }
    get dataAuthCode() {
        return 'SEARCH-ALL';
        // return this.listType === 'all' ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }
    public async fetchInstantlyMutatingDataById(id: any, processCtx?: any) {
        if (!this.defaultMeta) {
            return;
        }
        const { fieldAuthCode, dataAuthCode } = this;

        this.mutatingDataLoading = true;
        if (processCtx === undefined) {
            const [d, e] = await commonQueryProcess(
                this.defaultMeta.tableId,
                {
                    pageSize: 1,
                    pageNum: 1,
                },
                {
                    filters: [
                        {
                            fieldId: 'id',
                            filterValue: id,
                            operateType: '=',
                        },
                    ],
                },
                this.defaultSorter.sorterArgs,
                fieldAuthCode,
                undefined,
                dataAuthCode,
            );
            this.mutatingDataLoading = false;

            if (d === null || e !== null) {
                return false;
            }
            const { datas } = d;
            if (datas && datas[0]) {
                this.mutatingData = datas[0];
                return true;
            } else {
                message.error('没有该数据的权限');
                return false;
            }
        }
        const { instanceId, currentUser } = processCtx;
        if (!instanceId || !currentUser) {
            return false;
        }
        let [d, e] = await defaultAxios.post(
            `/bff/api/rest/process/category-list/?tableCode=${this.defaultMeta.tableId}&instanceId=${instanceId}&currentUser=${currentUser}`,
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        d = d.data;
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        if (!this.defaultMeta) {
            return [null, null];
        }
        const { fieldAuthCode, dataAuthCode } = this;

        const requsetCode = this.defaultMeta.tableId;
        const [d, e] = await commonQueryProcess(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                'process_instance_id',
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
            ],
            dataAuthCode,
        );

        if (requsetCode !== this.defaultMeta.tableId) {
            return [null, null];
        }

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public processCode = '';
    public setProcessCode(nextProcessCode: string) {
        this.processCode = nextProcessCode;
    }

    public async fetch2(processCode: string): Promise<TGetCommonReturnType> {
        if (!this.defaultMeta || !this.defaultMeta.tableId) {
            return [null, null];
        }
        const { fieldAuthCode, dataAuthCode } = this;

        const requsetCode = this.defaultMeta.tableId;
        const [d, e] = await commonQueryProcess2(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                'process_instance_id',
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
            ],
            dataAuthCode,
            undefined,
            processCode,
        );

        if (requsetCode !== this.defaultMeta.tableId) {
            return [null, null];
        }

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e as any];
    }

    get downloadUrl() {
        if (!this.defaultMeta) {
            return '';
        }
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);

        const { fieldAuthCode, dataAuthCode, processCode } = this;
        if (this.isCate2) {
            const objectName = this.defaultMeta.tableId;
            const fetchBody = getCommonProcessQueryBody(
                this.defaultPagination.paginationArgs,
                this.condListbyFilterData,
                this.defaultSorter.sorterArgs,
                fieldAuthCode,
                [
                    'process_instance_id',
                    ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                        item => item.key,
                    ),
                ],
                dataAuthCode,
                undefined,
            );
            fetchBody.type = 'process-cate2'; // hack
            fetchBody.objectName = objectName;
            fetchBody.processCode = processCode;
            return `${
                window.location.origin
            }/bff/api/rest/export?q=${encodeURIComponent(
                JSON.stringify(fetchBody),
            )}`;
        } else {
            const fetchBody = getCommonQueryBody(
                { ...this.defaultPagination.paginationArgs, pageSize },
                this.condListbyFilterData,
                this.defaultSorter.sorterArgs,
                fieldAuthCode,
                [
                    'process_instance_id',
                    ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                        item => item.key,
                    ),
                ],
                dataAuthCode,
                undefined,
            );
            fetchBody.objectName = objectName;
            return `${
                window.location.origin
            }/bff/api/rest/export?q=${encodeURIComponent(
                JSON.stringify(fetchBody),
            )}`;
        }
    }
    public processData: any[] = [];
    public processDataPagination = new PaginationStore();
    // 以流程的视角请求数据
    public async fetchRawProcess(
        useProcessDetail: boolean,
    ): Promise<[any, Error | null]> {
        // 根据四级菜单携带的数据请求
        const { selectedLv4MenuFilters } = this.defaultFastFilters;
        const { paginationArgs } = this.processDataPagination;

        if (!selectedLv4MenuFilters[0]) {
            return [[], null];
        }
        let { extra } = selectedLv4MenuFilters[0] || {};
        extra = extra || {};
        const payload = extra.payload || {};
        const api = extra.api;
        const role = extra.role || {};
        const requestPayload = {
            ...payload,
        };
        if (role && this.sysUser?.userId) {
            requestPayload[role] = this.sysUser?.userId;
        }

        const [d, e] = await defaultAxios.put(
            api,
            {
                pageQuery: {
                    ...paginationArgs,
                },
                ...requestPayload,
            },
            {
                timeout: 60000, // 设置60秒超时
            },
        );

        if (_.isNil(d) || !_.isNil(e) || !d.data) {
            return [d, e];
        }
        const { totalSize, data } = d.data;

        runInAction(() => {
            this.processData = data;
            this.processDataPagination.amount = totalSize;
        });
        return [d, null];
    }

    public async startProcess(
        body: {
            applicant: string;
            processDefinitionId: string;
            businessInfo: any;
        },
        tablecode: string | null,
        createProcessStore: ProcessCatStore,
    ) {
        if (!tablecode) {
            return false;
        }

        if (tablecode === 'contract') {
            const store = new ContractListStore();
            store.setMutatingData(_.cloneDeep(body.businessInfo));

            store.defaultMeta.setObjectMeta(
                createProcessStore.defaultMeta?.objectMeta || [],
            );
            store.defaultMeta.setProcessPermAll(
                createProcessStore.defaultMeta?.processPermAll,
            );
            const isSuccess = await store.create();
            createProcessStore.setNetValidateResult(store.netValidateResult);
            return isSuccess;
        } else {
            const data = defaultWritingDataParser(body.businessInfo);
            if (tablecode === 'process_contract_invoice_table') {
                if (_.isArray(data._invoice_reverse_code)) {
                    data.invoice_reverse_code = JSON.stringify(
                        data._invoice_reverse_code,
                    );
                } else {
                    data.invoice_reverse_code = null;
                }
                delete data._invoice_reverse_code;
            }

            const [ret, err] = await defaultAxios.put(
                '/crm/workflow/instance/start',
                {
                    ...body,
                    businessInfo: data,
                },
            );
            if (!ret) {
                message.error('新建流程失败');
                return false;
            }

            const { msg, code, data: innerData } = ret;

            if (_.isArray(innerData) && innerData.length > 0) {
                // 如果是数组就是一堆字段报错
                const formTopEle = $('.component-form-lv2')[0];
                if (formTopEle) {
                    (formTopEle as any).___withAutoScroll = true;
                }
                createProcessStore.setNetValidateResult(
                    transformBackEndValidateStateToValidateResult(innerData),
                );
                message.error('存在校验不通过的字段');
                return false;
            }

            this.resetAction();
            message.info('新建流程成功');
            this.defaultEE.emit('process-create-success');
            return true;
        }
    }

    public async updateProcess(body: any) {
        if (!this.defaultMeta) {
            return false;
        }

        const data = defaultWritingDataParser(_.cloneDeep(body.businessInfo));
        // 暂时不用对合同做特殊处理

        const [ret, err] = await defaultAxios.put(
            '/crm/workflow/instance/update',
            {
                ...body,
                businessInfo: data,
            },
        );
        if (!ret) {
            message.error('操作更新失败');
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }

        this.resetAction();
        this.defaultEE.emit('process-create-success');
        return true;
    }

    public async opProcess(body: any) {
        if (!this.defaultMeta) {
            return false;
        }

        const data = defaultWritingDataParser(_.cloneDeep(body.businessInfo));

        if (this.tableCode === 'process_contract_invoice_table') {
            if (_.isArray(data._invoice_reverse_code)) {
                data.invoice_reverse_code = JSON.stringify(
                    data._invoice_reverse_code,
                );
            } else {
                data.invoice_reverse_code = null;
            }
            delete data._invoice_reverse_code;
        }

        if (this.tableCode === 'contract') {
            try {
                const opportunityProductList = await parseFeProductToBackEndContractProduct(
                    data.product,
                    data,
                );
                data.contract_product_list = opportunityProductList;
                data.product = null;
            } catch (e) {
                message.error(e.message);
                return false;
            }

            const { _contract_code: contractCode } = data;
            if (!contractCode) {
                message.error('无contract_code');
                return false;
            }
            // 需要比较一下是否存在新文件，决定是否使用
            const {
                _contract_attachments: originalContractAttachmentsStr,
            } = data;
            const { contract_attachments: newContractAttachmentsStr } = data;
            try {
                const originalContractAttachments = JSON.parse(
                    originalContractAttachmentsStr,
                );
                const newContractAttachments = JSON.parse(
                    newContractAttachmentsStr,
                );
                let hasNewAttachment = false;
                const originalMap = arrToJson(
                    originalContractAttachments,
                    'url',
                );
                for (const newAtt of newContractAttachments) {
                    if (newAtt.url && !originalMap[newAtt.url]) {
                        hasNewAttachment = true;
                        break;
                    }
                }
                if (hasNewAttachment) {
                    data.contract_watermark = contractCode;
                }
            } catch (e) {
                message.error('文件解析出错');
                return false;
            }
        }

        const [ret, err] = await defaultAxios.put(
            '/crm/workflow/instance/operate',
            {
                ...body,
                businessInfo: data,
            },
        );
        if (!ret) {
            message.error('操作流程失败');
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }

        this.resetAction();
        message.info('操作流程成功');
        this.defaultEE.emit('process-create-success');
        return true;
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.id;
        delete data.key;
        const [ret, err] = await mutate('lead', 'insert', data);
        if (!ret) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }
    public async update() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('lead', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async delete() {
        if (!this.defaultMeta) {
            return false;
        }
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = [data.id];
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!e) {
            message.info('删除成功');
            return true;
        }
        return false;
    }

    public async mdelete() {
        if (!this.defaultMeta) {
            return false;
        }
        const ids = _.map(this.defaultMSelect.mSeletedData, item => item.id);
        if (ids.length === 0) {
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = ids;
        const [d, e] = await xDelete(tableId, operatingIdList);

        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('删除成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量删除结果', d.data);
            return false;
        }
    }

    public getProcessCreateCtx(sysUser: any, selectedLv4MenuFilters: any[]) {
        if (selectedLv4MenuFilters && selectedLv4MenuFilters[0]) {
            const selectedProcess = selectedLv4MenuFilters[0];
            const ctx: IProcessCtx = {};
            ctx.definitionId = selectedProcess.originalItem.id;
            ctx.currentUser = sysUser?.userId;
            return ctx;
        }
        return null;
    }

    public get processCreateCtx() {
        const { sysUser } = this;
        const { selectedLv4MenuFilters } = this.defaultFastFilters;

        if (selectedLv4MenuFilters && selectedLv4MenuFilters[0]) {
            const selectedProcess = selectedLv4MenuFilters[0];
            const ctx: IProcessCtx = {};
            ctx.definitionId = selectedProcess.originalItem.id;
            ctx.currentUser = sysUser?.userId;
            return ctx;
        }
        return null;
    }

    public get processCtx() {
        const { actionType, processCreateCtx } = this;
        if (actionType === 'create') {
            return processCreateCtx;
        }
        return null;
    }

    public tableCode: null | string = null;

    public setTableCode(nextTableCode: string) {
        this.tableCode = nextTableCode;
        this.defaultMeta = new ListMeta(
            '/bff/api/rest/meta/process/list?tableCode=' + this.tableCode,
            '/bff/api/rest/meta/process/list/filters/save?tableCode=' +
                this.tableCode,
            '/bff/api/rest/meta/process/list/table-cols/save?tableCode=' +
                this.tableCode,
        );
        this.defaultFilter = new FilterStore(
            this.defaultSorter,
            this.defaultMeta,
        );
        this.defaultPerm = new Perm(
            '/bff/api/rest/perm?mod=sales-follow-up:' + this.tableCode,
        );
        this.defaultPagination = new PaginationStore();
        this.defaultSorter = new SorterStore<any>();
        this.defaultMSelect = new MSelectStore();
        this.data = [];
        this.mutatingData = null;
    }

    public setTableCodeAny(nextTableCode: string, metaGetter?: any) {
        this.tableCode = nextTableCode;
        this.defaultMeta = new ListMeta(
            metaGetter
                ? metaGetter
                : '/bff/api/rest/meta/process/list?target=any&tableCode=' +
                  this.tableCode,
            '/bff/api/rest/meta/process/list/filters/save?tableCode=' +
                this.tableCode,
            '/bff/api/rest/meta/process/list/table-cols/save?tableCode=' +
                this.tableCode,
        );
        this.defaultFilter = new FilterStore(
            this.defaultSorter,
            this.defaultMeta,
        );
        this.defaultPerm = new Perm(
            '/bff/api/rest/perm?mod=sales-follow-up:' + this.tableCode,
        );
        this.defaultPagination = new PaginationStore();
        this.defaultSorter = new SorterStore<any>();
        this.defaultMSelect = new MSelectStore();
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultMeta: ListMeta | null = null;
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm | null = null;
    public defaultFastFilters = new FastFilterStore();
    public setFastFilterStore(next: FastFilterStore) {
        this.defaultFastFilters = next;
    }
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultFilter: false,
            defaultPerm: false,
            defaultEE: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });
    }

    // 合同开发票资质
    public async askInvoiceQualifyByContactId(contractId: any) {
        this.setInvoiceQualificationLoading(true);

        const customerId = contractId?.relatedObj?.customer_id;
        // 这逻辑太sh*t了
        const customerName =
            this.mutatingData.customer_name ||
            contractId?.relatedObj?.customer_name;
        if (_.isNil(customerId)) {
            this.invoiceQuilificationBad();
            message.error('请求合同开票资质出错');
            return false;
        }

        const [d1, e1] = await commonQuery(
            'invoice_qualification',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'customer_id',
                        filterValue: customerId,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            this.fieldAuthCode,
            undefined,
            this.dataAuthCode,
        );
        if (null === d1) {
            this.invoiceQuilificationBad();
            message.error('请求合同开票资质出错');
            return false;
        }

        this.setContractCustomer({
            originalValue: customerId,
            relatedObj: { id: customerId, customer_name: customerName },
            showFiledName: 'customer_name',
            showRelatedObj: null,
            type: 'obj',
        });
        this.setInvoiceQualificationInited(true);
        this.setInvoiceQualificationLoading(false);

        const { datas: datas1 } = d1;
        if (!datas1 || !datas1[0]) {
            this.setHasInvoiceQualification(false);
            this.setInvoiceQualificationData(null);
            return false;
        }
        this.setHasInvoiceQualification(true);
        this.setInvoiceQualificationData(datas1[0]);
        return true;
    }

    public contractCustomer: any = null;
    public setContractCustomer(next: any) {
        this.contractCustomer = next;
    }

    public invoiceQualificationLoading = false;
    public setInvoiceQualificationLoading(next: boolean) {
        this.invoiceQualificationLoading = next;
    }
    public invoiceQualificationInited = false;
    public setInvoiceQualificationInited(next: boolean) {
        this.invoiceQualificationInited = next;
    }
    public hasInvoiceQualification = false;
    public setHasInvoiceQualification(next: boolean) {
        this.hasInvoiceQualification = next;
    }
    public invoiceQualificationData: any = null;
    public setInvoiceQualificationData(next: any) {
        this.invoiceQualificationData = next;
    }

    public invoiceQuilificationBad() {
        this.setInvoiceQualificationLoading(false);
        this.setInvoiceQualificationInited(false);
        this.setHasInvoiceQualification(false);
        this.setInvoiceQualificationData(null);
    }

    public badInvoiceList: any[] = [];
    public badInvoiceListLoading = false;
    public badInvoiceListInited = false;
    get isbadInvoiceNoList() {
        return (
            this.badInvoiceListLoading === false &&
            this.badInvoiceList.length === 0 &&
            this.badInvoiceListInited
        );
    }

    get badInvoiceListOptions() {
        return this.badInvoiceList.map(item => {
            return {
                value: item.id,
                name:
                    item.invoice_no +
                    (!_.isNil(item.invoice_cost)
                        ? `（${item.invoice_cost}元）`
                        : ''),
            };
        });
    }

    get badInvoiceMap() {
        return arrToJson(this.badInvoiceList, 'id');
    }

    public dbBadInvoice2Fe(badInvoiceStr: string) {
        try {
            const badInvoice = JSON.parse(badInvoiceStr);
            if (_.isArray(badInvoice)) {
                this.badInvoiceList = badInvoice;
                return badInvoice.map((item: any) => Number(item.id));
            }
            return [];
        } catch (e) {
            return [];
        }
    }

    public feBadInvoice2db(badInvoice: any[]) {
        const nextBadInvoice: any[] = [];
        for (const id of badInvoice) {
            const optionOriginalItem = this.badInvoiceMap[id];
            if (!optionOriginalItem) {
                continue;
            }
            nextBadInvoice.push({
                id: optionOriginalItem.id,
                invoice_no: optionOriginalItem.invoice_no,
                invoice_id: optionOriginalItem.invoice_id,
                invoice_cost: optionOriginalItem.invoice_cost,
            });
        }
        return nextBadInvoice;
    }

    // 开发票，拿作废发票列表
    // 每次合同id变化都拿一次
    public async fetchBadInvoiceList(
        invoiceContractId: any,
        invoiceReverseApplyId: any,
    ) {
        const contractId = invoiceContractId?.originalValue;
        const reverseApplyId = invoiceReverseApplyId?.originalValue;
        if (!contractId || !reverseApplyId) {
            return false;
        }
        this.badInvoiceListLoading = true;
        const [d, e] = await commonQuery(
            'contract_invoice',
            {
                pageNum: 1,
                pageSize: 100,
            },
            {
                filters: [
                    {
                        fieldId: 'contract_id',
                        filterValue: contractId,
                        operateType: '=',
                    },
                    {
                        fieldId: 'invoice_status',
                        filterValue: '1',
                        operateType: 'in',
                    },
                    {
                        fieldId: 'invoice_apply_id',
                        filterValue: reverseApplyId,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            undefined,
            undefined,
            'SEARCH-ALL',
        );
        this.badInvoiceListLoading = false;
        this.badInvoiceListInited = true;
        const list = d?.datas;
        if (_.isArray(list)) {
            this.badInvoiceList = list;
            return true;
        }
        return false;
    }

    // 人才银行工作台类型，公司团队列表
    // product_open_team
    public customerTeamList: any[] = [];
    public customerTeamListLoading = false;
    get isCustomerWithTeamList() {
        return this.customerTeamList.length > 0;
    }
    public async fetchCustomerTeamList(cid: any) {
        if (!cid) {
            return false;
        }
        this.customerTeamListLoading = true;
        const [d, e] = await defaultAxios.get(
            '/crm/workflow/contractproduct/workteam',
            {
                cid,
            },
        );
        this.customerTeamListLoading = false;
        if (_.isArray(d?.data)) {
            d?.data.forEach((item: any) => {
                item.originalTeamName = item.team_name;
            });
            this.customerTeamList = d?.data;
        }

        // test
        // this.customerTeamList = [
        //     { id: '1', originalTeamName: 'apple', teamName: 'apple' },
        //     { id: '2', originalTeamName: 'orange', teamName: 'orange' },
        //     { id: '3', originalTeamName: '', teamName: '3' },
        // ];
    }

    public workbenceStatistics: { [key: string]: number } = {};

    public async fetchWorkbenceStatistics(contractProductId: any) {
        const cpid = contractProductId?.originalValue;
        if (!cpid) {
            return false;
        }
        const [
            d,
            e,
        ] = await defaultAxios.get(
            '/crm/workflow/contractproduct/remainequity',
            { contractProductId: cpid },
        );
        if (isNetSuccess(d, e)) {
            this.workbenceStatistics = d?.data;
        }
        // this.workbenceStatistics = {
        //     workbench_recruit_num: 1,
        //     workbench_deptment_num: 1,
        //     workbench_subadmin_num: 1,
        //     workbench_project_num: 1,
        //     workbench_recruit_project_num: 1,
        //     workbench_direct_num: 1,
        //     workbench_reach_num: 1,
        //     workbench_direct_month_num: 1,
        //     workbench_friend_month_num: 1,
        //     workbench_company_num: 1,
        // };
    }
}

export const getDefaultProcessCatStore = createDefaultInstanceGetterA(
    ProcessCatStore,
);
