import React, {
    memo,
    useEffect,
    useContext,
    useState,
    useCallback,
} from 'react';
import {
    DForm,
    IProcessCtx,
    IDFormProps,
    AntTable,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ProcessCatStore } from '../store';
import {
    arrToJsonPrimary,
    defaultAxios,
    isNetSuccess,
    arrToJson,
    makeStringValidator,
    useRefresh,
    decodePhone as phoneDecode,
} from '@/utils';
import { validators } from '@/pages/demo2/form2/options';
import { getValidatorByParamMeta } from '@/stores/utils';
import { applyCustomConfToWidget } from '@/components/common/form/advanced-filters';
import {
    talentBankVersion2ParamWhiteList,
    talentBankVersion3ParamWhiteList,
    talentBankVersionWorkbenchParamWhiteList,
    talentBankVersionWorkbenchParamWhiteArr,
    changeAdminGetRequiredAndHiddenParams,
    openProductChangeProductOpenTypeText,
    removeAdminNameParams,
    allAdminParams,
    getCusLocal,
} from './utils';
import { CtxContext } from '@/utils/context';
import { Alert, message, Icon, Form, Input } from 'antd';
import { seeContractProcess } from '@/pages/om/contract/list/store';
import { commonQuery } from '@/api/rest';
import querystring from 'querystring';
import { StoreContext } from '@/stores';
import { CustomerContactsCreate } from '@/components/common/customer-drawer/customer-contacts-create';
import moment from 'moment';
import './form.scss';

export const transferSingleParamList = [
    'customer_id',
    'relation_customer_id',
    'transfer_sale_before',
    'transfer_sale_after',
];
export const transferSingleParamMap = arrToJsonPrimary(transferSingleParamList);

const openAdminSuffixes = ['', '2', '3', '4', '5'];

const DemoForm: React.FC<{
    defaultStore: ProcessCatStore;
    processCtx: IProcessCtx | null;
    tableCode: string | null;
    mode: 'create' | 'update';
}> = ({ defaultStore, processCtx, tableCode, mode }) => {
    const ctx = useContext(CtxContext);
    const store = useContext(StoreContext);
    const userStore = store.getAuthStore();
    const supportedTypes =
        userStore.userInfo.supportedBizTypes?.['customer']?.businessTypeIds ||
        [];
    const customerDefaultFilters = [
        {
            fieldName: 'biz_type',
            operateType: 'IN',
            fieldValue: supportedTypes.length ? supportedTypes.join(',') : 'x',
        },
    ];
    const makeBizTypeFilter = (bizType: any) => {
        if (!bizType) {
            return [];
        }
        return [
            {
                fieldName: 'biz_type',
                operateType: 'EQUAL',
                fieldValue: bizType,
            },
        ];
    };

    const { position, positionDetail } = ctx;
    const isCreate = mode === 'create';
    const authStore = store.getAuthStore();
    const sysId = authStore?.userInfo?.sysUser?.id;

    const { defaultMeta, mutatingData: muData } = defaultStore;

    const [expireTime, setExpireTime] = useState(0);
    useEffect(() => {
        console.log('muData', muData, defaultStore);
        if (_.isNil(muData?.customer_id)) {
            setExpireTime(0);
            return;
        }
        if (muData?.customer_id?.originalValue) {
            defaultAxios
                .put(
                    '/crm/system/highsea/recycle/customer/' +
                        muData?.customer_id?.originalValue,
                )
                .then(([d, e]) => {
                    if (!_.isNil(e) || _.isNil(d)) {
                        return;
                    }
                    message.destroy();
                    if (d.data) {
                        message.success('刷新成功');
                        if (d.data.expireTime) {
                            const expireTime = moment(
                                d.data.expireTime,
                            ).valueOf();
                            setExpireTime(expireTime);
                            return;
                        }
                        setExpireTime(-1);
                    } else {
                        message.error('刷新失败,请重新进入该页面');
                        setExpireTime(0);
                    }
                });
        }
    }, [tableCode, muData?.customer_id, isCreate]);
    const [fillingLoading, setFillingLoading] = useState(false);

    const [createContactsTrigger, setCreateContactsTrigger] = useState(0);
    const [contactsRefreshTrigger, setContactsRefreshTrigger] = useState(0);
    const updateContactsRefreshTrigger = useCallback(() => {
        setContactsRefreshTrigger(contactsRefreshTrigger + 1);
    }, [contactsRefreshTrigger]);
    useRefresh(['process-contacts'], updateContactsRefreshTrigger);

    const [
        fillContractProductOpenLoading,
        setFillContractProductOpenLoading,
    ] = useState(false);

    const [saleaRatioErrMsg, setSaleaRatioErrMsg] = useState('');

    useEffect(() => {
        if (!muData?.opportunity_id || !isCreate) {
            return;
        }
        const optId = muData?.opportunity_id;
        if (tableCode === 'process_borrow_title_table') {
            setFillingLoading(true);
            defaultAxios
                .put(
                    '/bff/api/rest/populate?tableCode=process_borrow_title_table',
                    {
                        opportunity_id: optId.originalValue,
                    },
                )
                .then(([d, e]) => {
                    setFillingLoading(false);
                    if (isNetSuccess(d, e)) {
                        const data = d?.data;
                        delete data.opportunity_id;
                        delete data.customer_id;
                        _.assign(muData, data);
                    } else {
                        message.error('请求回填出错');
                    }
                });
        }

        if (tableCode === 'process_achievement_split_table') {
            setFillingLoading(true);
            defaultAxios
                .put(
                    '/bff/api/rest/populate?tableCode=process_achievement_split_table',
                    {
                        opportunity_id: optId.originalValue,
                    },
                )
                .then(([d, e]) => {
                    setFillingLoading(false);
                    if (isNetSuccess(d, e)) {
                        const data = d?.data;
                        delete data.opportunity_id;
                        delete data.customer_id;
                        _.assign(muData, data);
                    } else {
                        message.error('请求回填出错');
                    }
                });
        }

        if (tableCode === 'process_replace_payment_table') {
            setFillingLoading(true);
            defaultAxios
                .put(
                    '/bff/api/rest/populate?tableCode=process_replace_payment_table',
                    {
                        opportunity_id: optId.originalValue,
                    },
                )
                .then(([d, e]) => {
                    setFillingLoading(false);
                    if (isNetSuccess(d, e)) {
                        const data = d?.data;
                        delete data.opportunity_id;
                        delete data.opportunity_customer_id;
                        _.assign(muData, data);
                    } else {
                        message.error('请求回填出错');
                    }
                });
        }
    }, [tableCode, muData?.opportunity_id, isCreate]);

    useEffect(() => {
        if (
            !muData?.saleb_id?.originalValue ||
            tableCode !== 'process_achievement_split_table'
        ) {
            return;
        }
        const saleBId = muData?.saleb_id?.originalValue;
        defaultAxios
            .put(
                '/bff/api/rest/populate?tableCode=process_achievement_split_table',
                {
                    saleb_id: saleBId,
                },
            )
            .then(([d, e]) => {
                setFillingLoading(false);
                if (isNetSuccess(d, e)) {
                    const data = d?.data;
                    _.assign(muData, {
                        saleb_area: data?.saleb_area || null,
                    });
                } else {
                    message.error('请求回填出错');
                }
            });
    }, [tableCode, muData?.saleb_id]);

    useEffect(() => {
        if (!muData?.borrow_customer_id) {
            return;
        }
        const cusId = muData?.borrow_customer_id;
        if (tableCode === 'process_borrow_title_table') {
            muData.borrow_customer_name = cusId?.relatedObj?.customer_name;
        }
    }, [tableCode, muData?.borrow_customer_id]);

    useEffect(() => {
        if (!muData?.subcustomer_id) {
            return;
        }
    }, [tableCode, muData?.subcustomer_id]);

    const [subCuslistClearTrigger, setSubCusListClearTrigger] = useState(0);
    const [subCusLoading, setSubCusLoading] = useState(false);
    const [subCusRelated, setSubCusRelated] = useState(false);

    const requestRelatedState = (cid: string) => {
        // 请求是否关联过的状态
        defaultAxios
            .get('/crm/workflow/relationCustomer/subcheck?customerId=' + cid)
            .then(([d, e]) => {
                if (isNetSuccess(d, e) && _.isBoolean(d?.data)) {
                    setSubCusRelated(d?.data);
                }
            });
    };
    useEffect(() => {
        if (!muData?.subcustomer_id) {
            return;
        }
        const cid = muData?.subcustomer_id?.originalValue;
        if (!cid) {
            return;
        }
        requestRelatedState(cid);
    }, [muData?.subcustomer_id]);

    const requestSubCusInfo = (cid: string) => {
        const clear = () =>
            muData &&
            ((muData.subcustomer_legal_person = null),
            (muData.subcustomer_local = null));
        const clear2 = () => {
            if (muData) {
                muData.customer_myown = null;
            }
        };
        setSubCusRelated(false);
        if (!cid) {
            clear();
            clear2();
            return;
        }

        setSubCusLoading(true);
        commonQuery(
            'customer',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: cid,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            undefined,
            undefined,
            'SEARCH-ALL',
        ).then(([d, e]) => {
            setSubCusLoading(false);
            if (_.isNil(d) || e !== null) {
                clear();
                return;
            }
            const { datas } = d;
            if (datas && datas[0]) {
                const dt = datas[0];
                muData.subcustomer_legal_person = dt.legal_person;
                muData.subcustomer_local = getCusLocal(dt);
            } else {
                clear();
            }
        });

        commonQuery(
            'customer',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'customer_name',
                        filterValue:
                            muData?.subcustomer_id?.relatedObj?.customer_name,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            undefined,
            undefined,
            'SEARCH-PRIVATE',
        ).then(([d, e]) => {
            setSubCusLoading(false);
            if (_.isNil(d) || e !== null) {
                clear2();
                return;
            }
            muData.customer_myown =
                (d?.datas || []).length > 0 &&
                d?.datas?.[0].owner_id?.originalValue === sysId
                    ? 1
                    : 0;
        });
    };

    const [cidAdminList, setCidAdminList] = useState<any[]>([]);
    useEffect(() => {
        if (tableCode !== 'process_customer_admin_change_table') {
            return;
        }
        const cid = muData?.customer_cid;
        if (!cid) {
            return;
        }
        defaultAxios
            .get('/crm/admin/enterprise/adminList', { cid })
            .then(([d, e]) => {
                if (isNetSuccess(d, e) && _.isArray(d?.data)) {
                    setCidAdminList(d?.data || []);
                }
            });
        // cidAdminList
    }, [tableCode, muData?.customer_cid]);

    if (!defaultMeta) {
        return null;
    }
    const baiscWidgets = defaultMeta.defaultWidgetsAllWithoutGroupCheck;

    const collapseLayout = defaultMeta.defaultFormCollapseLayout;
    const shouldGroup = collapseLayout.length > 0;

    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([12, 12]);
    if (shouldGroup) {
        for (const colla of collapseLayout) {
            colla.layout = layout;
        }
    }

    if (processCtx === null || !processCtx.currentUser) {
        return null;
    }

    console.log('testtest', tableCode);
    const props: IDFormProps = {
        processCtx,
        defaultStore: defaultStore as any,
        // widgetsGenerator 生成的地方
        widgetsGenerator: store => {
            return store.defaultMeta.defaultWidgetsAllWithoutGroupCheck;
        },
    };

    if (shouldGroup) {
        props.options = {
            grouped: true,
        };
    } else {
        props.propsTransformer = props => {
            props.layout = layout;
            return props;
        };
    }

    const { mutatingData } = defaultStore;

    let extraContent: React.ReactNode = null;

    let tipsContent: React.ReactNode = null;
    // 关联客户申请
    if (
        tableCode === 'process_customer_relation_table' &&
        mutatingData !== null
    ) {
        const { customer_myown } = mutatingData;
        // customer_myown 是1，否0

        const originalOnChange = props.onChange;
        props.onChange = (key, value, widgetKey) => {
            if (key === 'customer_myown') {
                mutatingData.subcustomer_id = null;
                setSubCusListClearTrigger(1 + subCuslistClearTrigger);
            }

            if (key === 'subcustomer_id') {
                const cid = value?.originalValue;

                muData.subcustomer_id = value;
                if (cid) {
                    requestSubCusInfo(cid);
                }
            }

            if (key === 'customer_id') {
                const subBizType =
                    mutatingData?.subcustomer_id?.relatedObj?.biz_type;
                // 失配，清除
                if (value?.relatedObj?.biz_type !== subBizType) {
                    mutatingData.subcustomer_id = null;
                }

                const cid = value?.originalValue;
                const clear = () =>
                    muData &&
                    ((muData.customer_legal_person = null),
                    (muData.customer_local = null),
                    (muData.customer_myown = null));
                if (!cid) {
                    clear();
                    return;
                }

                commonQuery(
                    'customer',
                    {
                        pageSize: 1,
                        pageNum: 1,
                    },
                    {
                        filters: [
                            {
                                fieldId: 'id',
                                filterValue: cid,
                                operateType: '=',
                            },
                        ],
                    },
                    undefined,
                    undefined,
                    undefined,
                    'SEARCH-ALL',
                ).then(([d, e]) => {
                    if (_.isNil(d) || e !== null) {
                        clear();
                        return;
                    }
                    const { datas } = d;
                    if (datas && datas[0]) {
                        const dt = datas[0];
                        muData.customer_legal_person = dt.legal_person;
                        muData.customer_local = getCusLocal(dt);
                    } else {
                        clear();
                    }
                });
            }

            if (originalOnChange) {
                return originalOnChange(key, value, widgetKey);
            }
        };
        props.widgetsTransformer = originalWidgets => {
            const nextWidgets = [...originalWidgets];
            applyCustomConfToWidget(
                nextWidgets,
                'subcustomer_local',
                widget => {
                    widget.nextlineWidget = () => {
                        return (
                            <Alert
                                message={
                                    <div>
                                        <div>
                                            如果要关联的【主客户】或【子客户】找不到，请先按如下顺序处理
                                        </div>
                                        <div>
                                            1.
                                            进入对应的客户详情页，查看客户的主子客户关联记录
                                        </div>
                                        <div>
                                            2.
                                            如果该客户，目前是子客户，则不允许再关联其他客户。如有需要，先联系胥言，操作解绑
                                        </div>
                                        <div>
                                            3.
                                            如果该客户，目前是主客户，则不允许作为【子客户】，再关联到其他客户下。如有需要，先联系胥言，操作解绑
                                        </div>
                                        <div>
                                            4. 如果还有问题，请联系CRM产品
                                        </div>
                                    </div>
                                }
                            />
                        );
                    };
                    return widget;
                },
            );

            applyCustomConfToWidget(nextWidgets, 'customer_id', widget => {
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
                    widget.objectOptions.defaultFilters = [
                        {
                            fieldName: 'relate_type',
                            operateType: 'IN',
                            fieldValue: '0,2',
                        },
                        ...customerDefaultFilters,
                    ];
                    widget.objectOptions.appendFields = ['biz_type'];
                }
                return widget;
            });

            [
                'customer_myown',
                'subcustomer_legal_person',
                'subcustomer_local',
                'customer_legal_person',
                'customer_local',
            ].forEach(k => {
                applyCustomConfToWidget(nextWidgets, k, widget => {
                    widget.status = {
                        ...widget.status,
                        disabled: true,
                    };
                    return widget;
                });
            });

            const mainBizType = mutatingData?.customer_id?.relatedObj?.biz_type;
            applyCustomConfToWidget(nextWidgets, 'subcustomer_id', widget => {
                const isMine = customer_myown === 1;
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-CHOOSE';
                    widget.objectOptions.defaultFilters = [
                        {
                            fieldName: 'relate_type',
                            operateType: 'EQUAL',
                            fieldValue: 0,
                        },
                        ...makeBizTypeFilter(mainBizType),
                    ];
                    widget.objectOptions.appendFields = ['biz_type'];
                    widget.objectOptions.listClearTrigger =
                        isMine + mainBizType + '-' + subCuslistClearTrigger;
                }
                widget.status = {
                    ...widget.status,
                    loading: subCusLoading,
                };
                widget.nextlineWidget = () => {
                    if (!subCusRelated) {
                        return null;
                    }
                    return (
                        <div style={{ padding: '5px 0' }}>
                            <Alert
                                type="warning"
                                message="子客户曾在60天内作为主客户关联过其他客户"
                            />
                        </div>
                    );
                };
                if (!mainBizType && widget.status) {
                    widget.status.disabled = true;
                }
                return widget;
            });
            return nextWidgets;
        };
    }

    if (tableCode === 'process_borrow_title_table' && mutatingData !== null) {
        const originalOnChange = props.onChange;
        props.onChange = (key, value, widgetKey) => {
            if (key === 'customer_id') {
                mutatingData.opportunity_id = null;
                if (
                    mutatingData?.borrow_customer_id?.relatedObj?.biz_type !==
                    value?.relatedObj?.biz_type
                ) {
                    mutatingData.borrow_customer_id = null;
                    mutatingData.borrow_customer_name = null;
                }
            }

            if (originalOnChange) {
                return originalOnChange(key, value, widgetKey);
            }
        };
        props.widgetsTransformer = originalWidgets => {
            const nextWidgets = [...originalWidgets];

            applyCustomConfToWidget(nextWidgets, 'customer_id', widget => {
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
                    widget.objectOptions.defaultFilters = [
                        ...customerDefaultFilters,
                    ];
                    widget.objectOptions.appendFields = ['biz_type'];
                }
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'opportunity_id', widget => {
                widget.status = widget.status || {};
                widget.status.loading = () => {
                    return fillingLoading;
                };
                const cusId = mutatingData?.customer_id?.originalValue;
                if (!cusId) {
                    widget.status = {
                        ...widget.status,
                        disabled: true,
                    };
                }
                if (widget.objectOptions) {
                    widget.objectOptions.defaultFilters = cusId
                        ? [
                              {
                                  fieldName: 'customer_id',
                                  operateType: 'EQUAL',
                                  fieldValue: cusId,
                              },
                          ]
                        : [];
                    widget.objectOptions.reloadTrigger = cusId;
                }
                return widget;
            });

            const mainBizType = mutatingData?.customer_id?.relatedObj?.biz_type;
            applyCustomConfToWidget(
                nextWidgets,
                'borrow_customer_id',
                prevwidget => {
                    const widget = _.cloneDeep(prevwidget);
                    if (widget.objectOptions) {
                        widget.objectOptions.dataAuthCode = 'SEARCH-CHOOSE';
                        widget.objectOptions.defaultFilters = [
                            ...makeBizTypeFilter(mainBizType),
                        ];
                        widget.objectOptions.listClearTrigger =
                            '' + mainBizType;
                        widget.objectOptions.appendFields = ['biz_type'];
                    }

                    widget.status = {
                        ...widget.status,
                        disabled: !mainBizType,
                    };
                    return widget;
                },
            );

            return nextWidgets;
        };
    }

    if (
        tableCode === 'process_replace_payment_table' &&
        mutatingData !== null
    ) {
        const originalOnChange = props.onChange;
        props.onChange = (key, value, widgetKey) => {
            if (key === 'opportunity_customer_id') {
                mutatingData.opportunity_id = null;
                if (
                    mutatingData?.opportunity_customer_id?.relatedObj
                        ?.biz_type !== value?.relatedObj?.biz_type
                ) {
                    mutatingData.customer_id = null;
                    mutatingData.payment_title_id = null;
                    mutatingData.invoice_title_id = null;
                }
            }

            if (originalOnChange) {
                return originalOnChange(key, value, widgetKey);
            }
        };
        props.widgetsTransformer = originalWidgets => {
            const nextWidgets = [...originalWidgets];

            applyCustomConfToWidget(
                nextWidgets,
                'opportunity_customer_id',
                widget => {
                    if (widget.objectOptions) {
                        widget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
                        widget.objectOptions.defaultFilters = [
                            ...customerDefaultFilters,
                        ];
                        widget.objectOptions.appendFields = ['biz_type'];
                    }
                    return widget;
                },
            );

            applyCustomConfToWidget(
                nextWidgets,
                'opportunity_id',
                prevwidget => {
                    const widget = _.cloneDeep(prevwidget);
                    widget.status = widget.status || {};
                    widget.status.loading = () => {
                        return fillingLoading;
                    };
                    const cusId =
                        mutatingData?.opportunity_customer_id?.originalValue;
                    if (!cusId) {
                        widget.status = {
                            ...widget.status,
                            disabled: true,
                        };
                    }
                    if (widget.objectOptions) {
                        widget.objectOptions.defaultFilters = cusId
                            ? [
                                  {
                                      fieldName: 'customer_id',
                                      operateType: 'EQUAL',
                                      fieldValue: cusId,
                                  },
                              ]
                            : [];
                        widget.objectOptions.reloadTrigger = cusId;
                    }
                    return widget;
                },
            );

            const mainBizType =
                mutatingData?.opportunity_customer_id?.relatedObj?.biz_type;
            applyCustomConfToWidget(nextWidgets, 'customer_id', widget => {
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-CHOOSE';
                    widget.objectOptions.defaultFilters = [
                        ...makeBizTypeFilter(mainBizType),
                    ];
                    widget.objectOptions.listClearTrigger = '' + mainBizType;
                    widget.objectOptions.appendFields = ['biz_type'];
                }
                widget.status = {
                    ...widget.status,
                    disabled: !mainBizType,
                };
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'payment_title_id', widget => {
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-CHOOSE';
                    widget.objectOptions.defaultFilters = [
                        ...makeBizTypeFilter(mainBizType),
                    ];
                    widget.objectOptions.listClearTrigger = '' + mainBizType;
                    widget.objectOptions.appendFields = ['biz_type'];
                }
                widget.status = {
                    ...widget.status,
                    disabled: !mainBizType,
                };
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'invoice_title_id', widget => {
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-CHOOSE';
                    widget.objectOptions.defaultFilters = [
                        ...makeBizTypeFilter(mainBizType),
                    ];
                    widget.objectOptions.listClearTrigger = '' + mainBizType;
                    widget.objectOptions.appendFields = ['biz_type'];
                }
                widget.status = {
                    ...widget.status,
                    disabled: !mainBizType,
                };
                return widget;
            });

            return nextWidgets;
        };
    }

    if (
        tableCode === 'process_achievement_split_table' &&
        mutatingData !== null
    ) {
        const originalOnChange = props.onChange;
        props.onChange = (key, value, widgetKey) => {
            if (key === 'customer_id') {
                mutatingData.opportunity_id = null;
            }

            if (key === 'salea_ratio') {
                const isEmpty = !value || !_.trim(value);
                value = value
                    ? _.trim(value)
                        ? Number(_.trim(value))
                        : NaN
                    : NaN;
                const intVal = parseInt(value);
                const clear = () => {
                    mutatingData.salea_amount = null;
                    mutatingData.saleb_ratio = null;
                    mutatingData.saleb_amount = null;
                };
                setSaleaRatioErrMsg('');
                if (isEmpty) {
                    clear();
                } else if (_.isNaN(value)) {
                    clear();
                    setSaleaRatioErrMsg('当前输入不是数字');
                } else if (value <= 0 || value >= 100) {
                    clear();
                    setSaleaRatioErrMsg('当前输入不在0~100之间');
                } else if (value !== intVal) {
                    clear();
                    setSaleaRatioErrMsg('当前输入不是整数');
                } else {
                    const prediction_sale_amount =
                        mutatingData?.prediction_sale_amount;
                    if (!_.isNil(prediction_sale_amount)) {
                        // calc
                        mutatingData.salea_amount =
                            (prediction_sale_amount * value) / 100;
                        mutatingData.saleb_ratio = 100 - value;
                        mutatingData.saleb_amount =
                            prediction_sale_amount - mutatingData.salea_amount;
                    }
                }
            }

            if (originalOnChange) {
                return originalOnChange(key, value, widgetKey);
            }
        };
        props.widgetsTransformer = originalWidgets => {
            const nextWidgets = [...originalWidgets];

            applyCustomConfToWidget(nextWidgets, 'salea_ratio', widget => {
                widget.nextlineWidget = () => {
                    return (
                        <div style={{ padding: '5px 0' }}>
                            <Alert
                                type={saleaRatioErrMsg ? 'error' : 'info'}
                                message={
                                    <span>
                                        填写0-100之间的整数作为A所占销售额度的百分比
                                        {saleaRatioErrMsg ? (
                                            <span style={{ color: 'red' }}>
                                                （{saleaRatioErrMsg}）
                                            </span>
                                        ) : null}
                                    </span>
                                }
                            />
                        </div>
                    );
                };
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'customer_id', widget => {
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
                }
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'opportunity_id', widget => {
                widget.status = widget.status || {};
                widget.status.loading = () => {
                    return fillingLoading;
                };
                const cusId = mutatingData?.customer_id?.originalValue;
                if (!cusId) {
                    widget.status = {
                        ...widget.status,
                        disabled: true,
                    };
                }
                if (widget.objectOptions) {
                    widget.objectOptions.defaultFilters = cusId
                        ? [
                              {
                                  fieldName: 'customer_id',
                                  operateType: 'EQUAL',
                                  fieldValue: cusId,
                              },
                          ]
                        : [];
                    widget.objectOptions.reloadTrigger = cusId;
                }
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'salea_id', widget => {
                widget.status = widget.status || {};
                widget.status.disabled = true;
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'split_reason', widget => {
                widget.nextlineWidget = () => (
                    <div style={{ padding: '5px 0' }}>
                        <Alert
                            type="warning"
                            message="详细描述联合打单背景及双方主要贡献"
                        />
                    </div>
                );
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'split_range', widget => {
                widget.nextlineWidget = () => (
                    <div style={{ padding: '5px 0' }}>
                        <Alert
                            type="warning"
                            message="“我所在的区域” - “对方所在的区域”"
                        />
                    </div>
                );
                return widget;
            });

            // split_reason 拆单原因 “详细描述联合打单背景及双方主要贡献”
            // split_range  拆单范围 “我所在的区域” - “对方所在的区域”

            return nextWidgets;
        };
        // salea_id
    }

    // 如果是延期申请
    if (tableCode === 'process_customer_delay_table' && mutatingData !== null) {
        const cid = mutatingData?.customer_id?.originalValue;
        const cooperation_status =
            mutatingData?.customer_id?.relatedObj?.cooperation_status;
        props.widgetsTransformer = originalWidgets => {
            const nextWidgets = [...originalWidgets];
            applyCustomConfToWidget(nextWidgets, 'delay_days', widget => {
                widget.status = widget.status || {};
                widget.status.disabled = true;
                return widget;
            });

            applyCustomConfToWidget(baiscWidgets, 'customer_id', nextWidget => {
                if (nextWidget.objectOptions) {
                    nextWidget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
                }
                nextWidget.nextlineWidget = () => {
                    if (!cid) {
                        return null;
                    }
                    return (
                        // 可以在这加
                        <div style={{ paddingTop: 4 }}>
                            <a
                                onClick={() => {
                                    if (cid) {
                                        window.open(
                                            '/buy/custom/my-custom?' +
                                                querystring.stringify({
                                                    id: cid,
                                                    target:
                                                        'process_customer_delay_table',
                                                }),
                                            '__blank',
                                        );
                                    } else {
                                        message.error('合同id不存在');
                                    }
                                }}
                            >
                                查看客户
                                <Icon type="link" />
                            </a>
                            {cooperation_status === 2 ? (
                                <span
                                    style={{ color: 'red', marginLeft: '20px' }}
                                >
                                    该客户处于合作中状态，不可申请延期
                                </span>
                            ) : null}
                        </div>
                    );
                };
                return nextWidget;
            });

            applyCustomConfToWidget(nextWidgets, 'delay_days', widget => {
                widget.status = widget.status || {};
                widget.status.disabled = true;
                const preExpireTime = moment(expireTime)
                    .add(muData?.delay_days || 30, 'day')
                    .valueOf();
                let resNode: React.ReactNode;
                if (expireTime === 0) {
                    resNode = <></>;
                } else if (expireTime === -1) {
                    resNode = (
                        <div style={{ color: 'red' }}>该客户未设置到期时间</div>
                    );
                } else {
                    resNode = (
                        <>
                            <div className="delay-placeholder"></div>
                            <Form className="delay-form">
                                <Form.Item
                                    label={'延期后预计掉池时间'}
                                    validateStatus={
                                        preExpireTime <= moment().valueOf()
                                            ? 'error'
                                            : 'validating'
                                    }
                                    help={
                                        preExpireTime <= moment().valueOf()
                                            ? `延期后预计掉池时间小于当前时间，无法提交。更多疑问请联系销售BP或销售运营`
                                            : ``
                                    }
                                >
                                    <Input
                                        className="delay-form-input"
                                        placeholder={moment(
                                            preExpireTime,
                                        ).format('YYYY-MM-DD HH:mm:ss')}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Form>
                        </>
                    );
                }
                widget.nextlineWidget = () => {
                    return resNode;
                };

                return widget;
            });

            return nextWidgets;
        };
        tipsContent = (
            <div className="delay-tips">
                <div className="delay-tip-content">
                    <div className="delay-tip-content-header">
                        延期时间规则：
                    </div>
                    <ul>
                        <li>
                            1.延期申请通过后将在原有“客户到期时间”基础上增加30天（当天生效）。
                        </li>
                    </ul>
                </div>

                <div className="delay-tip-content">
                    <div className="delay-tip-content-header">
                        提交延期申请规则：
                    </div>
                    <ul>
                        <li>1.单个销售在单个月度内只有2次延期名额。</li>
                        <li>2.同一销售，针对同一客户仅支持申请2次延期。</li>
                    </ul>
                </div>
            </div>
        );
    }

    // 如果是客户划转
    if (
        tableCode === 'process_customer_transfer_table' &&
        mutatingData !== null
    ) {
        const { transfer_type } = mutatingData;
        const { objectMetaMap } = defaultMeta;
        props.widgetsTransformer = originalWidgets => {
            const nextWidgets = [...originalWidgets];
            // transfer_type 1单个 2多个
            nextWidgets.forEach(widget => {
                if (transferSingleParamMap[widget.key as string]) {
                    widget.status = widget.status || {};
                    widget.status.hidden = +transfer_type !== 1;
                }
            });

            applyCustomConfToWidget(nextWidgets, 'customer_id', widget => {
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-CHOOSE';
                    widget.objectOptions.defaultFilters = [
                        ...customerDefaultFilters,
                    ];
                }
                return widget;
            });

            return nextWidgets;
        };

        props.labelsTransformer = originalLabels => {
            const nextLabels = [...originalLabels];
            nextLabels.forEach(label => {
                if (transferSingleParamMap[label.key as string]) {
                    label.required = +transfer_type === 1;
                }
            });
            return nextLabels;
        };

        props.validatorsTransformer = originalValidators => {
            const nextValidators = { ...originalValidators };
            const keys = _.keys(nextValidators);
            keys.forEach(k => {
                if (transferSingleParamMap[k]) {
                    if (+transfer_type === 1) {
                        const paramsMeta = _.cloneDeep(objectMetaMap[k]);
                        paramsMeta.isRequired = true;
                        nextValidators[k] = getValidatorByParamMeta(paramsMeta);
                    }
                }
            });
            return nextValidators;
        };
    }
    const decodePhone = async (phoneNum: string) => {
        const url = '/crm/crypto/phone/decrypt';
        // setDecodeLoading(true);
        const [d, e] = await defaultAxios.post(url, {
            phone: phoneNum,
            scene: 'crm_contacts',
        });
        // setDecodeLoading(false);
        const data = phoneDecode(d?.data);
        const error = e as Error | null;
        if (data) {
            return data;
        } else {
            // message.error('解密手机号失败');
            return phoneNum;
        }
    };
    // 管理员变更
    if (
        tableCode === 'process_customer_admin_change_table' &&
        mutatingData !== null
    ) {
        const { objectMetaMap } = defaultMeta;
        const { product_type, change_type, talent_bank_version } = mutatingData;
        const cusId = mutatingData?.customer_id?.originalValue;
        const cusName = mutatingData?.customer_id?.relatedObj?.customer_name;

        extraContent = (
            <CustomerContactsCreate
                trigger={createContactsTrigger}
                customerInfo={{
                    id: cusId,
                    customer_name: cusName,
                }}
            />
        );

        const {
            requiredParams,
            hiddenParams,
        } = changeAdminGetRequiredAndHiddenParams(product_type, change_type);

        let changeTypeVals = new Set<any>([1, 2, 3]);
        if (product_type === 1) {
            // 人才银行
            changeTypeVals = new Set([2]);
        }

        if (product_type === 2) {
            // 企业号
            changeTypeVals = new Set([1, 2, 3]);
        }

        const originalOnChange = props.onChange;
        props.onChange = (key, value, widgetKey) => {
            if (
                tableCode === 'process_customer_admin_change_table' &&
                key === 'product_type' &&
                value === 1
            ) {
                mutatingData.change_type = 2;
            }

            if (
                tableCode === 'process_customer_admin_change_table' &&
                key === 'customer_id'
            ) {
                const clear = () => {
                    mutatingData.customer_cid = null;
                };
                allAdminParams.forEach(param => {
                    mutatingData[param] = null;
                });
                commonQuery(
                    'customer',
                    {
                        pageSize: 1,
                        pageNum: 1,
                    },
                    {
                        filters: [
                            {
                                fieldId: 'id',
                                filterValue: value?.originalValue,
                                operateType: '=',
                            },
                        ],
                    },
                    undefined,
                    undefined,
                    undefined,
                    'SEARCH-ALL',
                ).then(([d, e]) => {
                    setSubCusLoading(false);
                    if (_.isNil(d) || e !== null) {
                        clear();
                        return;
                    }
                    const { datas } = d;
                    if (datas && datas[0]) {
                        const dt = datas[0];
                        mutatingData.customer_cid = dt.customer_cid;
                    } else {
                        clear();
                    }
                });
            }

            if (
                tableCode === 'process_customer_admin_change_table' &&
                key === 'admin_id'
            ) {
                const contactsId = value?.originalValue;
                if (contactsId) {
                    defaultAxios
                        .get(
                            '/bff/api/rest/contacts/fillContractContactInfo?id=' +
                                contactsId,
                        )
                        .then(async ([d, e]) => {
                            if (isNetSuccess(d, e)) {
                                const data = d?.data;
                                if (mutatingData) {
                                    const pData = await decodePhone(
                                        data.contacts_phone,
                                    );
                                    mutatingData.admin_phone = pData;
                                }
                            }
                        });
                }
            }

            const k = key as string;
            if (
                tableCode === 'process_customer_admin_change_table' &&
                _.startsWith(k, 'new_admin_id')
            ) {
                const suffix = k.substring('new_admin_id'.length);
                const contactsId = value?.originalValue;
                if (contactsId) {
                    defaultAxios
                        .get(
                            '/bff/api/rest/contacts/fillContractContactInfo?id=' +
                                contactsId,
                        )
                        .then(async ([d, e]) => {
                            if (isNetSuccess(d, e)) {
                                const data = d?.data;
                                if (mutatingData) {
                                    const pData = await decodePhone(
                                        data.contacts_phone,
                                    );
                                    mutatingData[
                                        'new_admin_phone' + suffix
                                    ] = pData;
                                    // mutatingData.new_admin_phone =
                                    //     data.contacts_phone;
                                }
                            }
                        });
                }
                if (value === null) {
                    mutatingData['new_admin_phone' + suffix] = null;
                }
            }

            if (
                tableCode === 'process_customer_admin_change_table' &&
                _.startsWith(k, 'remove_admin_name')
            ) {
                const suffix = k.substring('remove_admin_name'.length);
                const name = value;
                const item = cidAdminList.find(
                    item => item.contacts_name === name,
                );
                if (item) {
                    mutatingData['remove_admin_phone' + suffix] = item.phone;
                }

                if (value === null) {
                    mutatingData['remove_admin_phone' + suffix] = null;
                }
            }

            if (originalOnChange) {
                return originalOnChange(key, value, widgetKey);
            }
        };

        props.widgetsTransformer = originalWidgets => {
            const nextWidgets = [...originalWidgets];
            applyConfToFormItem(nextWidgets, 'inputSecret', {
                sceneCode: 'crm_admin_change',
            });
            applyCustomConfToWidget(nextWidgets, 'change_type', widget => {
                const nextWidget = _.cloneDeep(widget);
                if (nextWidget.selectOptions) {
                    const opts = nextWidget.selectOptions.options;
                    nextWidget.selectOptions.options = opts.filter(item => {
                        return changeTypeVals.has(item.value);
                    });
                }
                return nextWidget;
            });

            [
                'admin_id',
                'new_admin_id',
                ...(() => {
                    const ret: string[] = [];
                    for (let i = 2; i <= 5; i++) {
                        ret.push('new_admin_id' + i);
                    }
                    return ret;
                })(),
            ].forEach(k => {
                applyCustomConfToWidget(nextWidgets, k, widget => {
                    if (widget.objectOptions) {
                        widget.objectOptions.defaultFilters = cusId
                            ? [
                                  {
                                      fieldName: 'customer_id',
                                      operateType: 'EQUAL',
                                      fieldValue: cusId,
                                  },
                              ]
                            : [];
                        widget.objectOptions.reloadTrigger =
                            contactsRefreshTrigger + ':' + cusId;
                    }
                    return widget;
                });
            });

            removeAdminNameParams.forEach(k => {
                // 这些是select类型
                applyCustomConfToWidget(nextWidgets, k, widget => {
                    widget.widgetKey = 'select';
                    widget.selectOptions = {
                        placeholder:
                            widget?.inputOptions?.placeholder ||
                            widget?.objectOptions?.placeholder ||
                            '',
                        options: cidAdminList.map(
                            ({ contacts_name, phone }) => {
                                return {
                                    name: contacts_name,
                                    value: contacts_name,
                                };
                            },
                        ),
                    };
                    if (widget.objectOptions) {
                        widget.objectOptions.defaultFilters = cusId
                            ? [
                                  {
                                      fieldName: 'customer_id',
                                      operateType: 'EQUAL',
                                      fieldValue: cusId,
                                  },
                              ]
                            : [];
                        widget.objectOptions.reloadTrigger =
                            contactsRefreshTrigger + ':' + cusId;
                    }
                    return widget;
                });
            });

            // 0: {constraintLabel: "是", constraintValue: 1,…}
            // 1: {constraintLabel: "否", constraintValue: 0,…}

            applyCustomConfToWidget(nextWidgets, 'admin_register', widget => {
                const nextWidget = _.cloneDeep(widget);
                if (mutatingData.admin_register === 0) {
                    nextWidget.nextlineWidget = () => (
                        <div style={{ padding: '5px 0' }}>
                            <Alert
                                type="warning"
                                message="管理员必须是脉脉注册用户"
                            />
                        </div>
                    );
                }
                return nextWidget;
            });

            applyCustomConfToWidget(nextWidgets, 'new_admin_phone', widget => {
                const nextWidget = _.cloneDeep(widget);
                nextWidget.nextlineWidget = () => {
                    if (cusId && cusName) {
                        return (
                            <div
                                onClick={() =>
                                    setCreateContactsTrigger(
                                        1 + createContactsTrigger,
                                    )
                                }
                            >
                                <a>
                                    新建联系人
                                    <Icon type="user" />
                                </a>
                            </div>
                        );
                    }
                    return null;
                };
                return nextWidget;
            });

            for (const k of Array.from(hiddenParams)) {
                applyCustomConfToWidget(nextWidgets, k, widget => {
                    const nextWidget = _.cloneDeep(widget);
                    nextWidget.status = {
                        ...nextWidget.status,
                        hidden: true,
                    };
                    return nextWidget;
                });
            }

            return nextWidgets;
        };

        // 如果选择了人才银行，自动给变为新增
        // 0: {constraintLabel: "新增", constraintValue: 1,…}
        // 1: {constraintLabel: "变更", constraintValue: 2,…}
        // 2: {constraintLabel: "减少", constraintValue: 3,…}

        props.labelsTransformer = originalLabels => {
            const nextLabels = [...originalLabels];
            nextLabels.forEach(label => {
                if (requiredParams.has(label.key as string)) {
                    label.required = true;
                }
            });
            return nextLabels;
        };

        props.validatorsTransformer = originalValidators => {
            const nextValidators = { ...originalValidators };
            for (const k of Array.from(requiredParams)) {
                const paramsMeta = _.cloneDeep(objectMetaMap[k]);
                paramsMeta.isRequired = true;
                nextValidators[k] = getValidatorByParamMeta(paramsMeta);
            }
            for (const k of Array.from(hiddenParams)) {
                delete nextValidators[k];
            }

            return nextValidators;
        };
    }

    if (tableCode === null) {
        return null;
    }

    console.log('delayprops', props);

    const form = (
        <>
            {extraContent}
            <DForm {...props} />
            {tipsContent}
        </>
    );
    return form;
};

const FinalDemoForm = observer(DemoForm);
export default FinalDemoForm;
