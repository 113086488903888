import React, { useState, useEffect, useContext } from 'react';
import { Radio, Icon, message, Button, Select, DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import './index.less';
import { TableLv2 } from '../../table/level2';
import { IMemberListData } from '../../../../pages/om/member/member-list/store';
import {
    MEMBER_IDENTITY_MAP,
    detailFieldsMap,
} from '../../../../pages/om/member/member-list/index';
import moment from 'moment';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { tryNewCall } from '@/containers/hoc/call-center';
import { IMemberListStagingData } from '../../../../pages/om/member/member-list-staging/store';
import { defaultAxios } from '@/utils';
import { AntButton } from '@/components/antd';
import { CC } from '../../table/cells/cc';
import qs from 'querystring';
import CryptoJS from 'crypto-js';

// 十六位十六进制数作为密钥
const SECRET_KEY = CryptoJS.enc.Utf8.parse('4123286782825529');
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse('4740443514203706');

/**
 * 加密方法
 * @param data
 * @returns {string}
 */
export function encrypt(data: string) {
    if (typeof data === 'object') {
        try {
            // eslint-disable-next-line no-param-reassign
            data = JSON.stringify(data);
        } catch (error) {
            console.log('encrypt error:', error);
        }
    }
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
}

export interface ICustomerMaiRightsProps {
    cid?: string | number;
    defaultCustomerName?: string;
    crm_id?: string | number;
}
export interface ITalentMemberData extends IMemberListData {}
export interface ITalentStagingMemberData extends IMemberListStagingData {}
export interface ITalentWorkBenchData {
    cid: string | number; // cid
    shortname: string; // 名称
    sale_type: number; // 销售类型
    auth_employee_authentication: number; // 员工认证
    auth_can_talk: number; // 员工可以聊
    auth_opinion: number;
    auth_asking: number; // 员工说
    opinion_words_num: number;
    expire_time: string;
    exposure_coins: number;
    clear_times: number;
    admin_list: {
        id: number;
        realname: string;
        mobile: string;
    }[];
}
export interface ITalentWorkUseData {
    base: {
        name: string;
        uid: number;
        position: string;
        memberStartTime: string;
        totalMemberDays: string;
        memberJoinTime: string;
        directLeft: number;
        virtualPhoneLeft: number;
        friendLeft: number;
        coinLeft: number;
    };
    active: {
        mmActiveDays: number;
        recruitmentActiveDays: number;
        activeRate: number;
    };
    findTalent: {
        totalSearchCount: number;
        totalTalentPv: number;
    };
    connection: {
        directOppoCount: number;
        directOppoReplyCount: number;
        virtualPhoneRequestCount: number;
        virtualPhoneCallCount: number;
        virtualPhoneConnectedCount: number;
        fastContactSendCount: number;
        fastContactReplyCount: number;
        friendAddRequestCount: number;
        friendAddSuccessCount: number;
    };
    jobsAndResume: {
        newJobsCount: number;
        validJobsCount: number;
        receivedResumeCount: number;
        handleResumeCount: number;
        interestResumeCount: number;
    };
}

export interface ITalentUseNewData {
    msg: string;
    result: string;
    total: number;
    code: number;
    data: any[];
    titles: {
        name: string;
        key: string;
        sub_titles: { name: string; key: string }[];
    }[];
}

const CustomerMaiRights: React.FC<ICustomerMaiRightsProps> = props => {
    const store = useContext(StoreContext);
    const memberStore = store.getDefaultMemberStore();
    const authStore = store.getAuthStore();

    const { fetchConfigData, configData } = memberStore;
    useEffect(() => {
        fetchConfigData();
    }, []);
    const [maiRightsSwitch, setMaiRightsSwitch] = useState('1'); // 1:人才银行 2:企业号
    const [talentLoading, setTalentLoading] = useState(false); // 人才银行loading
    const [talentMemberData, setTalentMemberData] = useState<any>([]);
    const [talentWorkBenchData, setTalentWorkBenchData] = useState<
        ITalentWorkBenchData[]
    >();
    const [talentTookerData, setTalentTookerData] = useState<any>();
    const [talentWorkBenchLoading, setTalentWorkBenchLoading] = useState(false);
    const [talentTookerLoading, setTalentTookerLoading] = useState(false);

    const [useDataFilter, setUseDataFilter] = useState<{
        teamAdmin?: string;
        start?: number;
        end?: number;
    }>({
        start: moment()
            .startOf('month')
            .startOf('day')
            .valueOf(),
        end: moment()
            .endOf('month')
            .endOf('day')
            .valueOf(),
    });
    const [teamAdminData, setTeamAdminData] = useState<
        {
            name: string;
            phone: number | string;
            id: string | number;
            admin_uid: number;
        }[]
    >([]);
    const [useDataLoading, setUseDataLoading] = useState(false);
    const [useDataOrigin, setUseDataOrigin] = useState<any>([]);

    const [useNewDataOrigin, setUseNewDataOrigin] = useState<
        ITalentUseNewData
    >();
    const [useDataPagination, setUseDataPagination] = useState({
        pageSize: 20,
        current: 1,
    });

    // const fetchUseData = async () => {
    //     const url = '/crm/equity/getTeamUserData';
    //     setUseDataLoading(true);
    //     const [d, e] = await defaultAxios.get(
    //         url,
    //         {
    //             // ...useDataFilter,
    //             start_time: useDataFilter.start,
    //             end_time: useDataFilter.end,
    //             cid: useDataFilter.teamAdmin,
    //         },
    //         {
    //             timeout: 400000,
    //         },
    //     );
    //     setUseDataLoading(false);
    //     if (d === null || d.data === null) {
    //         message.error('查询失败～');
    //         return [null, e];
    //     }
    //     const data = d.data;
    //     if (data && Array.isArray(data?.item)) {
    //         setUseDataOrigin(data.item);
    //     } else {
    //         setUseDataOrigin([]);
    //         message.error(data?.msg || '查询失败～');
    //     }
    // };

    const fetchUseNewData = async () => {
        const url = '/crm/equity/getTeamUseNewData';
        setUseDataLoading(true);
        const [d, e] = await defaultAxios.get(
            url,
            {
                start_time: moment(useDataFilter.start).format('YYYY-MM-DD'),
                end_time: moment(useDataFilter.end).format('YYYY-MM-DD'),

                co_id: useDataFilter.teamAdmin,
                admin_uid: teamAdminData.find(
                    item => item.id === useDataFilter.teamAdmin,
                )?.admin_uid,
                page: useDataPagination.current - 1,
                size: useDataPagination.pageSize,
            },
            {
                timeout: 400000,
            },
        );
        setUseDataLoading(false);
        if (d === null || d.data === null) {
            message.error('查询失败～');
            return [null, e];
        }
        const data = d.data;
        setUseNewDataOrigin(data);
    };

    // const exportUseData = () => {
    //     const exportUrl = `/bff/api/rest/member-list/export-use-data?cid=${useDataFilter.teamAdmin}&start_time=${useDataFilter.start}&end_time=${useDataFilter.end}`;
    //     window.open(exportUrl, '_blank');
    // };

    const exportUseNewData = () => {
        const exportUrl = `/bff/api/rest/member-list/export-use-new-data?${qs.stringify(
            {
                start_time: moment(useDataFilter.start).format('YYYY-MM-DD'),
                end_time: moment(useDataFilter.end).format('YYYY-MM-DD'),
                co_id: useDataFilter.teamAdmin,
                admin_uid: teamAdminData.find(
                    item => item.id === useDataFilter.teamAdmin,
                )?.admin_uid,
                // page: useDataPagination.current - 1,
                // size: useDataPagination.pageSize,
                page: 0,
                size: 99999,
            },
        )}`;
        window.open(exportUrl, '_blank');
    };

    useEffect(() => {
        if (useDataFilter.teamAdmin) {
            // fetchUseData();
            fetchUseNewData();
        }
    }, [useDataFilter]);

    // 翻页
    useEffect(() => {
        if (useDataFilter.teamAdmin) {
            fetchUseNewData();
        }
    }, [useDataPagination]);

    const fetchCustomerRights = async () => {
        if (!props.cid) {
            return;
        }
        setTalentLoading(true);
        const url = '/bff/api/rest/member-list/customer-member-list';
        const [d, e] = await defaultAxios.post(
            url,
            {
                cid: props.cid,
                customerId: props.crm_id,
            },
            {
                timeout: 400000,
            },
        );
        setTalentLoading(false);
        if (d === null || d.data === null) {
            message.error('查询失败～');
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data.data)) {
            setTalentMemberData(data.data);
            setTeamAdminData(
                _.map(data.data, item => {
                    return {
                        name: item.adm_name,
                        phone: item.adm_mobile,
                        id: item.id,
                        admin_uid: item.adm_uid,
                    };
                }),
            );
            setUseDataFilter({
                ...useDataFilter,
                teamAdmin: data.data?.[0] ? data.data?.[0].id : undefined,
            });
        } else {
            setTalentMemberData([]);
            message.error(data?.msg || '查询失败～');
        }
    };
    const fetchWorkBenchData = async () => {
        if (!props.cid) {
            return;
        }
        setTalentWorkBenchLoading(true);
        const url = '/bff/api/rest/member-list/customer-work-bench-list';
        const [d, e] = await defaultAxios.post(
            url,
            {
                cid: props.cid,
            },
            {
                timeout: 400000,
            },
        );
        setTalentWorkBenchLoading(false);
        if (d === null || d.data === null) {
            message.error('查询失败～');
            return [null, e];
        }
        const data = d.data;
        if (data && !data.msg) {
            setTalentWorkBenchData([data]);
        } else {
            setTalentWorkBenchData([]);
            message.error(data?.msg || '查询失败～');
        }
    };

    const fetchTookerData = async () => {
        if (!props.cid) {
            return;
        }
        setTalentTookerLoading(true);
        const url = '/crm/member/tuoketong/list';
        const [d, e] = await defaultAxios.get(
            url,
            {
                cid: props.cid,
            },
            {
                timeout: 400000,
            },
        );

        setTalentTookerLoading(false);
        if (d === null || d.data === null) {
            message.error('查询失败～');
            return [null, e];
        }
        const data = d.data;
        if (data && !data.msg) {
            setTalentTookerData(data);
        } else {
            setTalentTookerData([]);
            message.error(data?.msg || '查询失败～');
        }
    };
    // useEffect(() => {
    //     if (props.cid) {
    //         if(maiRightsSwitch == '1') {
    //             fetchCustomerRights();
    //         } else {
    //             fetchWorkBenchData();
    //         }
    //     }
    // }, [props.cid]);
    useEffect(() => {
        if (maiRightsSwitch == '1') {
            fetchCustomerRights();
        }
        if (maiRightsSwitch === '2') {
            fetchWorkBenchData();
        }
        if (maiRightsSwitch === '3') {
            fetchTookerData();
        }
    }, [maiRightsSwitch]);
    const renderMemberListTable = () => {
        const renderItemDetail = (
            fields: {
                field: string;
                label: string;
                suffix?: string;
                // tslint:disable-next-line:align
            }[],
            values: any,
        ) => {
            return (
                <div className="flex-column">
                    {fields.map(item => (
                        <span style={{ marginBottom: '6px' }} key={item.field}>
                            <span
                                style={{ width: '60px' }}
                                className="color-dilution no-break display-inline-block"
                            >
                                {item.label}:
                            </span>
                            <span className="no-break">
                                {`${values[item.field] || '- -'}${item.suffix ||
                                    ''}`}
                            </span>
                        </span>
                    ))}
                </div>
            );
        };
        const isStaging = talentMemberData.length
            ? talentMemberData[0].co_identity == 7
                ? true
                : false
            : false;
        return isStaging ? (
            <TableLv2<ITalentStagingMemberData>
                className={'talent-member-data-table'}
                loading={talentLoading}
                dataSource={talentMemberData}
                closeRowSelection={true}
                pagination={false}
                scroll={{ x: '2000', y: '500' }}
                columns={[
                    // {
                    //     title: '简称',
                    //     key: 'co_short_name',
                    //     dataIndex: 'co_short_name',
                    //     width: '160px',
                    // },
                    // {
                    //     title: '销售',
                    //     key: 'bd_name',
                    //     width: '80px',
                    //     dataIndex: 'bd_name',
                    // },
                    {
                        title: '账号类型',
                        dataIndex: 'co_identity',
                        key: 'co_identity',
                        width: '80px',
                        render: (
                            text: string,
                            row: ITalentStagingMemberData,
                            index: number,
                        ) => {
                            return MEMBER_IDENTITY_MAP?.[text]
                                ? MEMBER_IDENTITY_MAP?.[text]
                                : null;
                        },
                    },
                    {
                        title: '人才库版本',
                        dataIndex: 'talent_lib_version',
                        width: 100,
                        render: d => {
                            const { talent_lib_version } = configData;
                            if (
                                talent_lib_version &&
                                talent_lib_version.length
                            ) {
                                return (
                                    _.find(talent_lib_version, v => {
                                        return v.id === d;
                                    })?.name || ''
                                );
                            }
                        },
                    },
                    {
                        title: '团队名称',
                        dataIndex: 'team_name',
                        key: 'team_name',
                        width: '120px',
                    },
                    {
                        title: '管理员',
                        key: 'adm_name',
                        dataIndex: 'adm_name',
                        width: '120px',
                        render: (
                            text: string,
                            row: ITalentStagingMemberData,
                            index: number,
                        ) => {
                            return (
                                <span className="flex-column">
                                    <span className="np-break">{text}</span>
                                    <span className="np-break">
                                        {row.adm_mobile}
                                        {row.adm_mobile ? (
                                            <Icon
                                                onClick={() => {
                                                    tryNewCall(
                                                        row.adm_mobile as string,
                                                        true,
                                                        {
                                                            customerName:
                                                                props.defaultCustomerName,
                                                            customerId:
                                                                props.crm_id,
                                                            showFollowLog: true,
                                                        },
                                                    );
                                                }}
                                                style={{ cursor: 'pointer' }}
                                                type="phone"
                                                theme="twoTone"
                                                twoToneColor="#0052ff"
                                            />
                                        ) : null}
                                    </span>
                                </span>
                            );
                        },
                    },
                    {
                        title: '会员到期日',
                        key: 'mbr_end_time',
                        dataIndex: 'mbr_end_time',
                        width: '120px',
                        render: v => {
                            return v ? moment(v).format('YYYY-MM-DD') : null;
                        },
                    },
                    {
                        title: '招聘席位数',
                        key: 'talent_seat_num',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.talentSeatNum,
                                item,
                            );
                        },
                    },
                    {
                        title: '用人经理席位数',
                        key: 'depart_seat_num',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.departSeatNum,
                                item,
                            );
                        },
                    },
                    {
                        title: '子管理员数',
                        key: 'child_admin_num',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.childAdminNum,
                                item,
                            );
                        },
                    },
                    {
                        title: '立即沟通（团队）',
                        key: 'direct_oppo_left',
                        dataIndex: 'direct_oppo_left',
                    },
                    {
                        title: '虚拟电话（团队）',
                        key: 'reach_nbr_new_left',
                        dataIndex: 'reach_nbr_new_left',
                    },
                    {
                        title: '立即沟通(月配额)',
                        dataIndex: 'direct_oppo_monthly',
                        key: 'direct_oppo_monthly',
                        width: 150,
                    },
                    {
                        title: '加好友(月配额)',
                        dataIndex: 'add_fr_monthly',
                        key: 'add_fr_monthly',
                        width: 150,
                    },
                ]}
            />
        ) : (
            <TableLv2<ITalentMemberData>
                className={'talent-member-data-table'}
                loading={talentLoading}
                dataSource={talentMemberData}
                closeRowSelection={true}
                pagination={false}
                // pagination={{
                //     ...defaultMemberListPagination.paginationForUI,
                //     total: defaultMemberListPagination.amount,
                //     showSizeChanger: false,
                // }}
                // onPaginationChange={
                //     defaultMemberListPagination.setPaginationByUI
                // }
                scroll={{ x: '2000', y: '400' }}
                isFixedActions={false}
                columns={[
                    // {
                    //     title: '简称',
                    //     key: 'co_short_name',
                    //     dataIndex: 'co_short_name',
                    //     width: '160px',
                    // },
                    // {
                    //     title: '销售',
                    //     key: 'bd_name',
                    //     width: '80px',
                    //     dataIndex: 'bd_name',
                    // },
                    {
                        title: '账号类型',
                        dataIndex: 'co_identity',
                        key: 'co_identity',
                        width: '80px',
                        render: (
                            text: string,
                            row: IMemberListData,
                            index: number,
                        ) => {
                            return MEMBER_IDENTITY_MAP?.[text]
                                ? MEMBER_IDENTITY_MAP?.[text]
                                : null;
                        },
                    },
                    {
                        title: '管理员',
                        key: 'adm_name',
                        dataIndex: 'adm_name',
                        width: '120px',
                        render: (
                            text: string,
                            row: IMemberListData,
                            index: number,
                        ) => {
                            const accountType =
                                MEMBER_IDENTITY_MAP?.[row?.co_identity];
                            const isV4 = accountType === 'v4.1';
                            const enterpriseParams = {
                                uid: encrypt(row?.adm_uid),
                                co_id: encrypt(row?.id),
                                mbr_co_crtime: row?.cr_time,
                                crm_uid: encrypt(
                                    String(authStore?.userInfo?.userId),
                                ),
                            };

                            return (
                                <span className="flex-column">
                                    <span className="np-break">{text}</span>
                                    <span className="np-break">
                                        {row.adm_mobile}
                                        {row.adm_mobile ? (
                                            <Icon
                                                onClick={() => {
                                                    tryNewCall(
                                                        row.adm_mobile as string,
                                                        true,
                                                        {
                                                            customerName:
                                                                props.defaultCustomerName,
                                                            customerId:
                                                                props.crm_id,
                                                            userName:
                                                                row.adm_name,
                                                            showFollowLog: true,
                                                        },
                                                    );
                                                }}
                                                style={{ cursor: 'pointer' }}
                                                type="phone"
                                                theme="twoTone"
                                                twoToneColor="#0052ff"
                                            />
                                        ) : null}
                                    </span>

                                    {isV4 && (
                                        <span className="np-break">
                                            <Button
                                                size="small"
                                                type="link"
                                                onClick={() => {
                                                    window.open(
                                                        `https://enterprise.in.taou.com/ent/v41/data?${qs.stringify(
                                                            enterpriseParams,
                                                        )}`,
                                                        '_blank',
                                                    );
                                                }}
                                            >
                                                登录人才银行
                                            </Button>
                                        </span>
                                    )}
                                </span>
                            );
                        },
                    },
                    {
                        title: '会员到期日',
                        key: 'mbr_end_time',
                        dataIndex: 'mbr_end_time',
                        width: '120px',
                        render: v => {
                            return v ? moment(v).format('YYYY-MM-DD') : null;
                        },
                    },
                    {
                        title: '账号数',
                        dataIndex: 'license_nbr',
                        key: 'license_nbr',
                        width: '100px',
                        render: (v, item) => {
                            return v;
                        },
                    },
                    {
                        title: '总点数',
                        key: 'mm_coin_balance_total',
                        dataIndex: 'mm_coin_balance_total',
                        width: '80px',
                    },
                    {
                        title: '剩余点数',
                        dataIndex: 'mm_coin_balance_left',
                        key: 'mm_coin_balance_left',
                        width: '80px',
                    },
                    {
                        title: '加好友',
                        dataIndex: 'addfr_exchange',
                        width: '120px',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.addFr,
                                item,
                            );
                        },
                    },
                    {
                        title: '极速联系',
                        dataIndex: 'uh_exchange',
                        width: '120px',
                        render: (v, item) => {
                            return renderItemDetail(detailFieldsMap.uh, item);
                        },
                    },
                    {
                        title: '极速曝光',
                        dataIndex: 'exposure',
                        width: '120px',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.exposure,
                                item,
                            );
                        },
                    },
                    {
                        title: '团队超级曝光余额',
                        key: 'super_exposure',
                        dataIndex: 'super_exposure',
                        width: '120px',
                    },
                    {
                        title: '人才库版本',
                        dataIndex: 'talent_lib_version',
                        width: 100,
                        render: d => {
                            const { talent_lib_version } = configData;
                            if (
                                talent_lib_version &&
                                talent_lib_version.length
                            ) {
                                return (
                                    _.find(talent_lib_version, v => {
                                        return v.id === d;
                                    })?.name || ''
                                );
                            }
                        },
                    },
                    {
                        title: '每日虚拟电话权益上限',
                        dataIndex: 'reach_nbr',
                        key: 'reach_nbr',
                        width: 150,
                    },
                    {
                        title: '关注公司数量',
                        dataIndex: 'attention_company_nbr',
                        key: 'attention_company_nbr',
                        width: 100,
                    },
                    // 这里有变更
                    {
                        title: '立即沟通(月配额)',
                        dataIndex: 'direct_oppo_monthly',
                        key: 'direct_oppo_monthly',
                        width: 150,
                    },
                    {
                        title: '加好友(月配额)',
                        dataIndex: 'add_fr_monthly',
                        key: 'add_fr_monthly',
                        width: 150,
                    },
                    {
                        title: '团队虚拟电话剩余(年配额)',
                        dataIndex: 'reach_nbr_new_left',
                        key: 'reach_nbr_new_left',
                        width: 150,
                    },
                    {
                        title: '团队立即沟通剩余(年配额)',
                        dataIndex: 'direct_oppo_left',
                        key: 'direct_oppo_left',
                        width: 150,
                    },
                ]}
            />
        );
    };
    const renderWorkBenchInfo = () => {
        return (
            <TableLv2<ITalentWorkBenchData>
                loading={talentWorkBenchLoading}
                dataSource={talentWorkBenchData}
                closeRowSelection={true}
                pagination={false}
                className={'talent-member-data-table'}
                // pagination={{
                //     ...defaultMemberListPagination.paginationForUI,
                //     total: defaultMemberListPagination.amount,
                //     showSizeChanger: false,
                // }}
                // onPaginationChange={
                //     defaultMemberListPagination.setPaginationByUI
                // }
                scroll={{ x: '2000', y: '500' }}
                isFixedActions={false}
                columns={[
                    {
                        title: '企业号版本',
                        key: 'sale_type',
                        dataIndex: 'sale_type',
                        width: '120px',
                        render: v => {
                            if (v == 0) {
                                return '基础门户版';
                            } else {
                                return '招聘加速版';
                            }
                        },
                    },
                    {
                        title: '是否为免费版',
                        key: 'is_sale_free',
                        dataIndex: 'is_sale_free',
                        width: '120px',
                        render: v => {
                            if (v == 0) {
                                return '付费版';
                            } else {
                                return '免费版';
                            }
                        },
                    },
                    {
                        title: '到期时间',
                        key: 'expire_time',
                        width: '120px',
                        dataIndex: 'expire_time',
                    },
                    {
                        title: '管理员（手机号）',
                        key: 'admin',
                        width: '200px',
                        render: (item: ITalentWorkBenchData) => {
                            return item.admin_list
                                ? item.admin_list.map(v => {
                                      return (
                                          <p
                                              className={'work-bench-admin'}
                                              key={v.id}
                                          >
                                              {`${v.realname}（${v.mobile}）`}{' '}
                                              {v.mobile ? (
                                                  <Icon
                                                      onClick={() => {
                                                          tryNewCall(
                                                              v.mobile,
                                                              true,
                                                              {
                                                                  customerName:
                                                                      props.defaultCustomerName,
                                                                  customerId:
                                                                      props.crm_id,
                                                                  userName:
                                                                      v.realname,
                                                                  showFollowLog: true,
                                                              },
                                                          );
                                                      }}
                                                      style={{
                                                          cursor: 'pointer',
                                                          display: 'inline',
                                                      }}
                                                      type="phone"
                                                      theme="twoTone"
                                                      twoToneColor="#0052ff"
                                                  />
                                              ) : null}
                                          </p>
                                      );
                                  })
                                : null;
                        },
                    },
                    {
                        title: '曝光币余额',
                        key: 'exposure_coins',
                        dataIndex: 'exposure_coins',
                        width: '90px',
                    },
                    {
                        title: '澄清次数余额',
                        key: 'clear_times',
                        dataIndex: 'clear_times',
                        width: '90px',
                    },
                    {
                        title: '雇主口碑关键词数',
                        key: 'opinion_words_num',
                        dataIndex: 'opinion_words_num',
                        width: '120px',
                    },
                    {
                        title: '员工认证',
                        dataIndex: 'auth_employee_authentication',
                        key: 'auth_employee_authentication',
                        width: '80px',
                        render: (v, item) => {
                            return v === 0 ? '不开通' : '开通';
                        },
                    },
                    {
                        title: '员工可以聊',
                        dataIndex: 'auth_can_talk',
                        key: 'auth_can_talk',
                        width: '80px',
                        render: (v, item) => {
                            return v === 0 ? '不开通' : '开通';
                        },
                    },
                    {
                        title: '问员工',
                        dataIndex: 'auth_asking',
                        key: 'auth_asking',
                        width: '80px',
                        render: (v, item) => {
                            return v === 0 ? '不开通' : '开通';
                        },
                    },
                    {
                        title: '投诉降权',
                        dataIndex: 'complaint_num',
                        key: 'complaint_num',
                        width: '90px',
                    },
                ]}
            />
        );
    };

    const renderTookerInfo = () => {
        return (
            <TableLv2
                loading={talentTookerLoading}
                dataSource={talentTookerData}
                closeRowSelection={true}
                pagination={false}
                className={'talent-member-data-table'}
                // pagination={{
                //     ...defaultMemberListPagination.paginationForUI,
                //     total: defaultMemberListPagination.amount,
                //     showSizeChanger: false,
                // }}
                // onPaginationChange={
                //     defaultMemberListPagination.setPaginationByUI
                // }
                scroll={{ x: '2000', y: '500' }}
                isFixedActions={false}
                columns={[
                    {
                        title: '公司名称',
                        key: 'company',
                        dataIndex: 'company',
                        width: 120,
                    },
                    {
                        title: '团队ID',
                        key: 'license_id',
                        dataIndex: 'license_id',
                        width: 100,
                    },
                    {
                        title: '加好友余额',
                        key: 'addfr_cnt',
                        dataIndex: 'addfr_cnt',
                        width: 100,
                    },
                    {
                        title: '极速联系余额',
                        key: 'uh_cnt',
                        dataIndex: 'uh_cnt',
                        width: 100,
                    },
                    {
                        title: '席位数量',
                        key: 'sub_license',
                        dataIndex: 'sub_license',
                        width: 100,
                    },
                    {
                        title: '电话数量',
                        key: 'telephone_cnt',
                        dataIndex: 'telephone_cnt',
                        width: 100,
                    },
                    {
                        title: '脉客券数量',
                        key: 'promote_cnt',
                        dataIndex: 'promote_cnt',
                        width: 100,
                    },
                    {
                        title: '电话直连数量',
                        key: 'direct_mobile_cnt',
                        dataIndex: 'direct_mobile_cnt',
                        width: 100,
                    },
                    {
                        title: '是否基础版',
                        key: 'is_sale',
                        dataIndex: 'is_sale',
                        width: 100,
                        render: (v: number) => {
                            return v ? '是' : '否';
                        },
                    },
                    {
                        title: '批量功能子账号',
                        key: 'is_batch',
                        dataIndex: 'is_batch',
                        width: 100,
                    },
                    {
                        title: '是否营销',
                        key: 'is_market',
                        dataIndex: 'is_market',
                        width: 100,
                        render: (v: number) => {
                            return v ? '是' : '否';
                        },
                    },
                ]}
            />
        );
    };

    const dateRangeValue: any = [
        useDataFilter?.start ? moment(useDataFilter?.start) : undefined,
        useDataFilter?.end ? moment(useDataFilter.end) : undefined,
    ];
    return (
        <div className="customer-mai-rights-content">
            <Radio.Group
                value={maiRightsSwitch}
                onChange={e => {
                    setMaiRightsSwitch(e.target.value);
                }}
                style={{
                    marginTop: '10px',
                }}
            >
                <Radio.Button value="1">人才银行</Radio.Button>
                <Radio.Button value="2">企业号</Radio.Button>
                <Radio.Button value="3">拓客通</Radio.Button>
            </Radio.Group>
            <div className="mai-rights-inner-content">
                <div className="mai-rights-item">
                    <p className="mai-rights-title">
                        基础合作信息
                        {/* {maiRightsSwitch == '1' ? (
                            <Button
                                onClick={() => {
                                    const url = `${window.location.origin}/bff/api/rest/member-list/export-customer-member-list?cid=${props.cid}`;
                                    window.open(url, '_blank');
                                }}
                                size={'small'}
                            >
                                导出
                            </Button>
                        ) : null} */}
                    </p>
                    {maiRightsSwitch === '1' && renderMemberListTable()}
                    {maiRightsSwitch === '2' && renderWorkBenchInfo()}
                    {maiRightsSwitch === '3' && renderTookerInfo()}
                </div>
                {maiRightsSwitch == '1' ? (
                    <div className="mai-rights-item">
                        <p className="mai-rights-title">
                            团队使用数据-累计数据
                            <a
                                style={{
                                    fontSize: '12px',
                                    position: 'absolute',
                                    left: '180px',
                                }}
                                target="_blank"
                                href="https://maimai.feishu.cn/wiki/wikcn7fU3i2ntx0B1Z29xs3czCe?sheet=0PGSHZ"
                            >
                                <Icon
                                    style={{
                                        marginRight: '2px',
                                    }}
                                    type="question-circle"
                                />
                                口径说明
                            </a>
                        </p>
                        <div className="mai-rights-filter">
                            <div className="mai-rights-filter-item">
                                <span className="mai-rights-filter-label">
                                    团队管理员
                                </span>
                                <Select
                                    loading={talentLoading}
                                    value={useDataFilter?.teamAdmin}
                                    onChange={(value: any) => {
                                        setUseDataFilter({
                                            ...useDataFilter,
                                            teamAdmin: value,
                                        });
                                    }}
                                    style={{
                                        width: '150px',
                                    }}
                                    dropdownMatchSelectWidth={false}
                                >
                                    {_.map(teamAdminData, item => {
                                        console.log(item.id);
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                // className={`cid-${item.id}`}
                                            >{`${item.name}-${item.phone}`}</Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div className="mai-rights-filter-item">
                                <span className="mai-rights-filter-label">
                                    时间周期
                                </span>
                                <DatePicker.RangePicker
                                    value={dateRangeValue}
                                    // width={230}
                                    showTime={true}
                                    format="YYYY-MM-DD"
                                    onChange={dates => {
                                        setUseDataFilter({
                                            ...useDataFilter,
                                            start: dates[0]
                                                ? dates[0].valueOf()
                                                : undefined,
                                            end: dates[1]
                                                ? dates[1].valueOf()
                                                : undefined,
                                        });
                                    }}
                                    style={{ width: '230px' }}
                                    allowClear={false}
                                />
                            </div>
                            <AntButton
                                disabled={!useDataFilter?.teamAdmin}
                                type="primary"
                                onClick={fetchUseNewData}
                            >
                                查询
                            </AntButton>
                            <AntButton
                                disabled={!useDataFilter?.teamAdmin}
                                onClick={exportUseNewData}
                            >
                                导出
                            </AntButton>
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <TableLv2
                                closeRowSelection={true}
                                className={'talent-member-data-table'}
                                loading={useDataLoading}
                                dataSource={useNewDataOrigin?.data.map(item => {
                                    // 当前是否绑定 = 否 ：后4项资产剩余展示为 -
                                    const { asset } = item;
                                    if (asset) {
                                        const { bind_st = '' } = asset;
                                        if (bind_st === '否') {
                                            for (let i in item.asset) {
                                                if (i !== 'bind_st') {
                                                    item.asset[i] = '-';
                                                }
                                            }
                                        }
                                    }
                                    return item;
                                })}
                                scroll={{
                                    x: 3000,
                                }}
                                pagination={{
                                    total: useNewDataOrigin?.total,
                                    pageSize: useDataPagination.pageSize,
                                }}
                                onPaginationChange={({ current, pageSize }) => {
                                    setUseDataPagination({
                                        current,
                                        pageSize: pageSize!,
                                    });
                                }}
                                columns={useNewDataOrigin?.titles?.map(
                                    title => ({
                                        title: title.name,
                                        children: title.sub_titles?.map(
                                            subTitle =>
                                                subTitle.key === 'mobile'
                                                    ? {
                                                          title: subTitle.name,
                                                          dataIndex: `${title.key}.${subTitle.key}`,
                                                          width: '140px',
                                                          render: (
                                                              v,
                                                              r: any,
                                                          ) => {
                                                              return v ? (
                                                                  <CC
                                                                      args={[
                                                                          r?.[
                                                                              title
                                                                                  .key
                                                                          ]?.[
                                                                              'emobile'
                                                                          ],
                                                                      ]}
                                                                      config={{
                                                                          customerId:
                                                                              props.crm_id,
                                                                          customerName:
                                                                              props.defaultCustomerName,
                                                                          userName:
                                                                              r
                                                                                  .base_info
                                                                                  .lic_sub_name,
                                                                      }}
                                                                      isHidden={
                                                                          true
                                                                      }
                                                                      sceneCode="crm_customer"
                                                                      outerHiddenNumber={
                                                                          v
                                                                      }
                                                                  />
                                                              ) : null;
                                                          },
                                                      }
                                                    : {
                                                          title: subTitle.name,
                                                          dataIndex: `${title.key}.${subTitle.key}`,
                                                      },
                                        ),
                                    }),
                                )}
                            />
                        </div>

                        {/* <TableLv2
                            pagination={false}
                            closeRowSelection={true}
                            className={'talent-member-data-table'}
                            loading={useDataLoading}
                            dataSource={useDataOrigin}
                            scroll={{
                                x: 3000,
                            }}
                            columns={[
                                {
                                    title: 'license基础信息',
                                    children: [
                                        {
                                            title: '姓名',
                                            dataIndex: 'base.name',
                                        },
                                        {
                                            title: '手机',
                                            dataIndex: 'base.emobile',
                                            width: '140px',
                                            render: (v, r: any) => {
                                                return v ? (
                                                    <CC
                                                        args={[v]}
                                                        config={{
                                                            customerId:
                                                                props.crm_id,
                                                            customerName:
                                                                props.defaultCustomerName,
                                                        }}
                                                        isHidden={true}
                                                        sceneCode={
                                                            'crm_customer'
                                                        }
                                                        outerHiddenNumber={
                                                            r?.base
                                                                ?.emobile_star
                                                        }
                                                    />
                                                ) : null;
                                            },
                                        },
                                        {
                                            title: 'uid',
                                            dataIndex: 'base.uid',
                                        },
                                        {
                                            title: '身份',
                                            dataIndex: 'base.position',
                                        },
                                        {
                                            title: '会员开通日期',
                                            dataIndex: 'base.member_start_time',
                                        },
                                        {
                                            title: '累计会员在线天数',
                                            dataIndex: 'base.total_member_days',
                                        },
                                        {
                                            title: '加入会员时间',
                                            dataIndex: 'base.member_join_time',
                                        },
                                        {
                                            title: '立即沟通剩余量',
                                            dataIndex: 'base.direct_left',
                                        },
                                        {
                                            title: '虚拟电话剩余量',
                                            dataIndex:
                                                'base.virtual_phone_left',
                                        },
                                        {
                                            title: '加好友剩余量',
                                            dataIndex: 'base.friend_left',
                                        },
                                        {
                                            title: '点数剩余量（2.0）',
                                            dataIndex: 'base.coin_left',
                                        },
                                    ],
                                },
                                {
                                    title: '活跃程度',
                                    children: [
                                        {
                                            title: '脉脉活跃天数',
                                            dataIndex: 'active.mm_active_days',
                                        },
                                        {
                                            title: '招聘活跃天数(人才天数)',
                                            dataIndex:
                                                'active.recruitment_active_days',
                                        },
                                        {
                                            title: '累计招聘活跃度',
                                            dataIndex: 'active.active_rate',
                                        },
                                    ],
                                },
                                {
                                    title: '发现人才',
                                    children: [
                                        {
                                            title: '累计搜索次数',
                                            dataIndex:
                                                'find_talent.total_search_count',
                                        },
                                        {
                                            title: '累计候选人浏览量',
                                            dataIndex:
                                                'find_talent.total_talent_pv',
                                        },
                                    ],
                                },
                                {
                                    title: '连接人才',
                                    children: [
                                        {
                                            title: '立刻沟通发送量',
                                            dataIndex:
                                                'connection.direct_oppo_count',
                                        },
                                        {
                                            title: '立刻沟通回复量',
                                            dataIndex:
                                                'connection.direct_oppo_reply_count',
                                        },
                                        {
                                            title: '虚拟电话索要量',
                                            dataIndex:
                                                'connection.virtual_phone_request_count',
                                        },
                                        {
                                            title: '虚拟电话拨打量',
                                            dataIndex:
                                                'connection.virtual_phone_call_count',
                                        },
                                        {
                                            title: '虚拟电话接通量',
                                            dataIndex:
                                                'connection.virtual_phone_connected_count',
                                        },
                                        {
                                            title: '加好友量',
                                            dataIndex:
                                                'connection.friend_add_request_count',
                                        },
                                        {
                                            title: '加好友量通过量',
                                            dataIndex:
                                                'connection.friend_add_success_count',
                                        },
                                    ],
                                },
                                {
                                    title: '职位与简历',
                                    children: [
                                        {
                                            title: '新增职位数',
                                            dataIndex:
                                                'jobs_and_resume.new_jobs_count',
                                        },
                                        {
                                            title: '有效职位数',
                                            dataIndex:
                                                'jobs_and_resume.valid_jobs_count',
                                        },
                                        {
                                            title: '收简历',
                                            dataIndex:
                                                'jobs_and_resume.received_resume_count',
                                        },
                                        {
                                            title: '处理简历',
                                            dataIndex:
                                                'jobs_and_resume.handle_resume_count',
                                        },
                                        {
                                            title: '感兴趣简历',
                                            dataIndex:
                                                'jobs_and_resume.interest_resume_count',
                                        },
                                    ],
                                },
                            ]}
                        />
                        <AntButton
                            disabled={!useDataFilter?.teamAdmin}
                            onClick={exportUseData}
                        >
                            导出
                        </AntButton> */}
                    </div>
                ) : (
                    undefined
                )}
            </div>
        </div>
    );
};

export default observer(CustomerMaiRights);
